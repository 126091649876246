<template>
  <div :id="id" style="height: 100%;"></div>
</template>
<script>
  import VueTmap from './tmap'
  export default {
    name:"les-map",
    props: ["options"],
    data(){
      return{
        id: new Date().getTime().toString(),
        mapObj:""
      }
    },
    methods:{

    },
    mounted(){
        this.mapObj = new VueTmap({
            center:[118.731694, 32.003552],
            zoom:14
        },this.id);
       this.$emit('on-map', this.mapObj);

    }
  }
</script>
