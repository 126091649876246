<template>
  <div>
<!--    <div class="print-img"><van-button type="default"  @click="toImg">打&nbsp;&nbsp;&nbsp;印</van-button></div>-->
    <div ref="printContent" style="background: #fff;">
      <div class="title">{{checkTitle}}检查表</div>
      <div class="content">
        <div class="item">
          <span class="col-6" style="padding: 0px;">
            <span style="display: inline-block;width: 100%;position: relative;left: 1px;border-right: 1px solid #ebedf0;">
            场所名称
              </span>
          </span>
          <span class="col-18 des">{{recordData.venue.name}}</span>
        </div>
        <div class="item">
          <span class="col-6" style="padding: 0px;">
            <span style="display: inline-block;width: 100%;position: relative;left: 1px;border-right: 1px solid #ebedf0;">
            场所地址
              </span>
          </span>
          <span class="col-18 des">{{recordData.venue.address}}</span>
        </div>
        <div class="item">
            <span class="col-17 center">检查内容</span>
            <span class="col-7 center">检查情况</span>
        </div>
        <div class="item" v-for="(item,k,index) in itemJson" v-bind:key="index">
          <div v-if="k!=='根据机位设置情况抽查实名登记上网执行情况'">
            <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
                {{k}}
             </div>

         </span>
            <span class="col-2"></span>
            <span class="col-22 little-item">
             <span class="des" v-for="(d,i) in item" v-bind:key="d.id">
               <span class="label">{{i+1}}.{{d.name}}</span>
               <span class="option">
                 <van-radio-group v-model="formData[d.id]['value']" direction="horizontal" disabled>
                    <van-radio name="1" icon-size="24px">是</van-radio>
                    <van-radio name="0" icon-size="24px">否</van-radio>
                  </van-radio-group>
               </span>
             </span>
            <span v-if="k=='实名登记上网管理'">
              <span  class="des" v-for="(cu,id) in customer" v-bind:key="'customers'+ id">
                <span class="des" v-for="(c,ind) in cu" v-bind:key="'customer'+ ind">
                 <span class="label">{{ind+1}}.{{c.name}}</span>
                 <span class="option" >
                   <van-radio-group v-model="c['value']" direction="horizontal" disabled v-if="c.type=='radio'">
                      <van-radio name="1" icon-size="24px">是</van-radio>
                      <van-radio name="0" icon-size="24px">否</van-radio>
                    </van-radio-group>
                   <span v-else-if="c.type=='textarea'">{{c.value}}</span>
                 </span>

               </span>
              </span>

            </span>
          </span>
          </div>

        </div>
        <div class="item">
          <span class="center vertical-align">
            <div style="writing-mode:tb-rl;">
            存在问题整改要求
            </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item problemContent des">
              &nbsp;存在问题:
              <div v-for="p in recordData.check.problemList" v-bind:key="p.id" style="padding-left: 10px;">
                  {{p.type.name}}:{{p.checkResult}}
              </div>
             &nbsp;整改要求:
              <div v-if='formData["check"]["des"]' style="padding-left: 10px;">
               {{ formData["check"]["des"]}}
              </div>
              <div v-else style="padding-left: 10px;">
                无
              </div>
              <div style="text-align: right;">
               <img :src="recordData.check.checkerSign" height="80px">
             </div>
            <span style="position: absolute;bottom: 0px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">日期:&nbsp;{{recordData.check.createTime}}</span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               场所人员确认
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item rectifyContent des">
              &nbsp;确认意见及整改承诺:

            <div style="text-align: right;">
             <img :src="recordData.check.checkedSign" height="80px">
           </div>
            <span style="position: absolute;bottom: 0px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">日期:&nbsp;{{recordData.check.rectifyTime}}</span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               整改情况
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item rectifiedContent des">
              <div v-for="p in recordData.check.problemList" v-bind:key="p.id" style="padding-left: 10px;">
                  {{p.type.name}}:{{p.rectifyResult}}
              </div>
            <span style="position: absolute;bottom: 0px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">日期:&nbsp;{{recordData.check.rectifyTime}}</span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               附件
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item picContent">
            <div v-if="recordData.check && recordData.check.problemList && recordData.check.problemList.length>0">
                <div v-for="p in recordData.check.problemList" v-bind:key="p.id" style="padding-left: 10px;">
                  {{p.type.name}}:{{p.checkResult}}
                   <div>检查照片</div>
                <div v-if="p.recordFileList && p.recordFileList.length>0">
                    <img  v-for="(pic,ind) in p.recordFileList" v-bind:key="ind" :src="pic" alt="" width="95%">
                </div>
                  <div v-if="formData[p.typeId] && formData[p.typeId].files">
                       <img  v-for="(pic,ind) in formData[p.typeId].files" v-bind:key="'pro'+ind" :src="pic" alt="" width="95%">
                  </div>

                <div v-if="p.rectifyFileList && p.rectifyFileList.length>0">
                    <div>整改照片</div>

                      <img  v-for="(pic,ind) in p.rectifyFileList" v-bind:key="ind" :src="pic" alt=""  width="95%">
                    </div>
                  </div>

            </div>
            <div v-else>
              无
            </div>

          </span>
        </div>

        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               现场照片
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item picContent">
            <div v-if="recordData.check.picture" style="padding: 16px;">
                <img  v-for="(pic,ind) in recordData.check.pictures" v-bind:key="'pics'+ ind" :src="pic" alt="" width="95%">
            </div>
            <div v-else>
              无
            </div>

          </span>
        </div>
      </div>
    </div>
    <div class="list-btn">
      <van-button size="large" type="primary" @click="toImg" :loading="loading">保存为图片</van-button>
    </div>
    <van-popup closeable  close-icon-position="top-left" v-model="shareShow" position="right"  :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div style="padding: 50px 16px 10px 50px;">长按图片保存</div>
      <img :src="img" alt="">
    </van-popup>
  </div>
</template>

<script>
import {RenderCheckDict} from "@/api/dict"
import html2canvas from 'html2canvas'
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';

export default {
  components:{
    [Button.name]: Button,
    [Popup.name]: Popup  ,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  name: "tableItem",
  data(){
    return {
      checkTitle:"",
      items:{},
      itemJson:{},
      formData:{},
      img:"",
      shareShow:false,
      loading:false,
      customer:[]
    }
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
          venue:{},
          check:{}
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        for(let m in n.dict){
          if(n.dict[m].length>0){
            n.dict[m].map(item=>{
              if(!this.items[item.id]){
                this.items[item.id] = item;
              }
            })
          }
        }
        if(n.check.picture){
          n.check.pictures = n.check.picture.split(",");
        }
        if(n.check && n.check.content){
          let formC = [];
          let json = JSON.parse(n.check.content);
          for(let k in json){
            if(json[k]['group']=='根据机位设置情况抽查实名登记上网执行情况'){
              formC.push({
                ...json[k],
                name:this.items[k]?this.items[k].name:"",
                value:json[k].type=='textarea'?json[k].des:json[k].value
              });
            }
          }
          this.formData = JSON.parse(n.check.content);
          for(let k in this.formData){
            if(this.formData[k].fileList){
              this.formData[k].files = this.formData[k].fileList.split(",");
            }
          }
          this.customer = [];
          if(formC.length>0){
            this.customer.push(formC);
          }
          if(json["customer"]){
            for(let c in json["customer"]){
              this.customer.push(json["customer"][c]);
            }

          }
        }
        this.itemJson = n.dict;

      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
    console.log(this.recordData)
  },
  methods:{
    parseContent(){

    },
    preview(oper)
      {
        if (oper < 10){
        let bdhtml=window.document.body.innerHTML;//获取当前页的html代码
          let sprnstr="<!--startprint"+oper+"-->";//设置打印开始区域
          let eprnstr="<!--endprint"+oper+"-->";//设置打印结束区域
          let prnhtml=bdhtml.substring(bdhtml.indexOf(sprnstr)+18); //从开始代码向后取html
        prnhtml=prnhtml.substring(0,prnhtml.indexOf(eprnstr));//从结束代码向前取html
        window.document.body.innerHTML=prnhtml;
        window.print();
        window.document.body.innerHTML=bdhtml;
      } else {
        window.print();
      }
     },
    toImg() { // 转图片打印
      this.loading = true;
      html2canvas(this.$refs["printContent"], {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        // let url = canvas.toDataURL('image/jpeg', 1.0)
        const url = canvas.toDataURL();
        this.img = url;
        this.loading = false;
        this.shareShow = true;
        /*console.log(url,"url")
        var a = document.createElement('a');
        a.href = url;
        a.download = "photo";
        let e = new MouseEvent("click");
        a.dispatchEvent(e);*/
        /*
        printJS({
          printable: url,
          type: 'image',
          documentTitle: '打印图片'
        })*/
      })
    },
  }
}
</script>
<style scoped>
/deep/ .van-radio__icon{
  font-size: 16px !important;
}
/deep/ .van-button{
  height: 36px;
  border-radius: 4px;
}
/deep/ .van-radio--horizontal{
  margin-right: 4px;
}
</style>
<style scoped lang="less">
.list-btn{
  padding:20px 0px;
}
  .print-img{
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .title{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .content{
     padding:16px;

       .item{
         box-sizing: border-box;
         width: 100%;
         border: 1px solid #ebedf0;
         line-height: 30px;
         border-bottom: none;
         position: relative;
         span{
           display: inline-block;
           box-sizing: border-box;
           padding: 0px 5px;
           border-left: 1px solid #ebedf0;

           span{
             border: none;
             border-bottom: 1px solid #ebedf0;
             width: calc(100% + 1px);
             border-right:1px solid #ebedf0;
           }
           span:last-child{
             border-bottom: none;
           }
         }
         .vertical-align{
           position: absolute;
           width: 10%;
           top: 50%;
           left: -3px;
           transform: translate(0, -50%);
         }

         .little-item{
           padding: 0px;
           .label{
             width: 67.6%;
             border-bottom: none;
           }
           .option{
             width: 32.4%;
             border-right: none;
             position: relative;
             left: -1px;
             border-left: 1px solid #ebedf0;
           }
         }
         .problemContent{
           color: #666;
           min-height: 180px;
           padding-bottom: 30px;
           position: relative;
         }
         .rectifyContent{
           color: #666;
           min-height: 130px;
           padding-bottom: 30px;
           position: relative;
         }
         .rectifiedContent{
           color: #666;
           min-height: 100px;
           padding-bottom: 30px;
           position: relative;
         }
         .picContent{
           color: #666;
           min-height: 180px;
           position: relative;
           img{
             margin-right: 10px;
           }
         }
         span:first-child{
           border-left: none;
         }
       }
      .des{
        font-size: 14px;
        color: #666;
      }
      .item:last-child{
        border-bottom: 1px solid #ebedf0;
      }
      .col-2{
        width: 10%;
      }
      .col-6{
        width: 25%;
      }
      .col-18{
        width: calc(75% - 0px);
      }
      .col-17{
        width: calc(70% - 0px);
      }
      .col-7{
        width: calc(30%);
      }
      .col-22{
        width: calc(90% - 0px);
      }

      .center{
        text-align: center;
      }
  }
</style>
