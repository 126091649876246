<template>
  <div style="position:relative;width: calc(100vw);height: calc(100vh - 50px);overflow-y: scroll;">
<!--    <div class="form-title">{{venue.name}}</div>-->
    <van-form @submit="onSubmit" @failed="failed" :label-width="labelWidth" ref="checkForm">
      <van-notice-bar
          v-if="tempShow"
          :scrollable="false"
          color="#1989fa" background="#ecf9ff" left-icon="info-o"
          :text="tempTime"
      />
      <div v-if="Object.keys(groupForm).length>0">
        <div v-for="(items,k,i) in groupForm" v-bind:key="'form'+i">
          <div class="form-title">{{ k }}</div>
          <div v-for="(obj, key) in items" v-bind:key="key">

            <div class="item-label">
              <span class="label-tips">*</span>
              {{obj.sort +'.' + obj.name}}
            </div>
            <van-field
                :name="obj.id"
                label-width="10"
                :label="''"
                :placeholder="obj.name"
                :rules="[{ required: true, message: '必填'}]"
            >
              <template #input>
                <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal">
                  <van-radio name="1" icon-size="24px">是</van-radio>
                  <van-radio name="0" icon-size="24px">否</van-radio>
                </van-radio-group>
              </template>

            </van-field>
            <div v-show="formData[obj.id] && formData[obj.id]['value']==='0'">
              <!--          <div class="form-title">检查出的问题及整改要求</div>-->

              <van-field name="remark" rows="3" autosize
                         :rules="[{ validator, message: '',ruleId:obj.id  }]"
                         type="textarea" v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label=""
                         placeholder="检查出的问题及整改要求">
                <span class="label-tips">*</span>
              </van-field>
              <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>拍照上传</div>
              <div class="form-image">
                <van-field name="uploader" :rules="[{ validator:validatorPic, message: '至少上传一张照片', ruleId:obj.id  }]">

                  <template #input>
                    <van-uploader :name="'fileList-'+obj.id" v-model="formData[obj.id].fileList" @delete="del"  capture="camera"  :before-read="beforeRead" :after-read="afterRead" :max-count="6" />
                  </template>
                </van-field>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>处理意见</div>
      <van-field
          name="check"
          type="textarea"
          v-model="formData.check.des"
          autosize
          placeholder="请输入处理意见"
      />

      <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
      <div>
        <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('checkedSign')">
          <div v-if="checkedSigned">
            <img :src="venueData.checkedSign" height="80px">
          </div>
        </div>
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人签名</div>
      <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('checkerSign')">
        <div v-if="checkerSigned">
          <img :src="venueData.checkerSign" height="80px">
        </div>
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人</div>
      <van-field
          name="check"
          v-model="venueData.remark"
          label=""
          placeholder="检查人"
      />
      <div class="form-label">现场照片</div>
      <div class="form-image">
        <van-field name="uploader">
          <template #input>
            <van-uploader v-model="pics" capture="camera" :before-read="beforeRead" @delete="del" :after-read="sendFile" :max-count="6" />
          </template>
        </van-field>
      </div>
      <div style="height: 60px;"></div>
      <div style="margin-bottom:10px;width: calc(100vw);">
        <van-row  type="flex" justify="center"  gutter="0">

          <van-col span="12" v-if="venue.checkType!='1'">
            <div class="temp-save-btn" @click="tempSave">暂存</div>
<!--            <van-button  size="large" type="warning" @click="tempSave">暂存</van-button>-->
          </van-col>
          <van-col :span="venue.checkType!='1'?12:24">
            <van-button size="large" type="primary" :loading="loading" native-type="submit">提交</van-button>
          </van-col>
        </van-row>
<!--        <van-uploader v-model="fileTest"  :before-read="beforeRead" :after-read="afterRead" :max-count="6" />-->

      </div>
    </van-form>
    <van-dialog v-model="show" title="确认提交？" show-cancel-button @cancel="cancleConfirm" @confirm="confirm">
      <van-notice-bar
          v-if="noticeShow"
          :scrollable="false"
          :text="des"
      />
      <div style="padding: 10px 0px 16px;">
        <div class="confirm-item" v-for="(obj, key) in confirmForm" v-bind:key="key">
          {{obj.sort +'.' + obj.name}}
        </div>
      </div>

<!--      <van-form :label-width="labelWidth - 60" ref="checkForm">
      <van-field v-for="(obj, key) in confirmForm" v-bind:key="key"
                 :name="obj.id"
                 :label="obj.sort +'.' + obj.name"
                 :placeholder="obj.name"
      >
        <template #input>
          <van-radio-group v-model="formData[obj.id]" direction="horizontal" disabled>
            <van-radio name="1" icon-size="24px">是</van-radio>
            <van-radio name="0" icon-size="24px">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      </van-form>-->
    </van-dialog>
      <van-popup v-model="signShow" position="bottom" :style="{ height: '100%' }" :closeable="true" get-container="#app" >
        <signature @getSign="getSign" :signer="signer"></signature>
      </van-popup>
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }">

       <div >
        <div class="model-title">
          <van-icon name="arrow-left" size="28" />
        </div>
         <div class="success-icon">
           <van-icon name="passed" color="#00E68F" size="40" />
         </div>
        <div class="success-content" v-if="temp">
          暂存成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
        </div>
        <div class="success-content" v-else>提交成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
<!--          ,查看<a href="javascript:void(0)" @click="viewDetail">检查详情</a>-->
        </div>
        </div>
       </van-popup>
  </div>
</template>

<script>
import {RenderCheckDict} from "@/api/dict"
import signature from "./signature";
import {
  Toast,Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';
import Compressor from 'compressorjs';
export default {
  components:{signature,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    venue:{
      type:Object,
      default: ()=>{
        return {
          name:"场馆1",
          id:"1"
        }
      }
    },
    userInfo:{
      type:Object,
      default: ()=>{
        return {
          openId:localStorage.getItem("wljptuser")
        }
      }
    },
    checkBegin:{
      default: ()=>{
        return new Date().getTime()
      }
    },
  },
  computed: {
    tempshow() {
      return this.tempTime?true:false;
    }
  },
  watch: {
    /*监听主题变化*/
    venue: {
      handler(n, o) {
        console.log(n,"report",this.dictJson);
        if(Object.keys(this.dictJson).length>0){
          this.parseDict();
        }
        this.venueData.infoId = n.id;
        this.venueData.status = -1;
        this.venueData.checkType = n.checkType;
        this.venueData.checkerSign = "";
        this.venueData.checkedSign = "";
        this.venueData.checkerSigned = false;
        this.venueData.checkedSigned = false;
        this.pics = [];
        this.successPop = false;
        this.seconds = 5;
        this.checkedTime = new Date().getTime();
      },
      deep: true,
      immediate:true
    }
  },
  data(){
    return {
      checkedTime:0,
      checkTimeTimer:null,
      currentPos:[],
      signShow:false,
      tempShow:false,
      labelWidth:window.innerWidth - 165,
      formDict1:{},
      formDict2:{},
      formDict3:{},

      options:{},
      venueData:{
        remark: GlobalConfig.dev?GlobalConfig.userName:localStorage.getItem("wljptuserName"),
        rectify:"",
        type:"yqfk",
        infoId:this.venue.id,
        checkerSign:"",
        checkedSign:"",
        status:-1,
        person:GlobalConfig.dev?GlobalConfig.openid:localStorage.getItem("wljptuser")
      },
      pics:[],
      fileList:{},
      formData:{
        // "0001": null,
        // "0002": null,
        // "0003": null,
        // "0004": null,
        // "0005": null,
        // "0006": null,
        // "0007": null,
        // "0008": null,
        // "0009": null,
        // "0010": null,
        // "0011": null,
        // "0012": null,
        // "0013": null,
        // "0014": null,
        // "0015": null,
        // "0016": null,
        // "0017": null,
        "check":{
          value:null,
          des:"",
          fileList:[]
        }
      },
      uploader:[],
      loading:false,
      noticeShow:false,
      show:false,
      successPop:false,
      checkerSigned:false,
      checkedSigned:false,
      temp:true,
      des:"",
      confirmForm:{},
      seconds:5,
      checkData:{},
      timer:null,
      timeOuter:null,
      tempTime:"",
      fileTest:[],
      signer:"",
      dictJson:{},
      groupForm:{},
      groupType:"yqfk"
    }
  },
  methods:{
    // 解析不同类型的表格
    parseDict(){
      let table = {};
      let all = {};
      for(let k in this.dictJson){
        if(k=='all'){
          all = this.dictJson[k];
        }
        if(k.indexOf("," + this.venue.type + ",")>-1){
          table = this.dictJson[k];
        }
      }
      if(Object.keys(table).length==0 || table.typeList==0){
        table = all;
      }
      this.groupType = table.id;
      let group = {};
      table.typeList.map((item,index)=>{
        if(!item.groupName){
          item.groupName = "其他";
        }
        if(!group[item.groupName]){
          group[item.groupName] = [];
        }
        item.sort = index+1;
        group[item.groupName].push(item);
        if(item.type=='radio1'||item.type=='checkbox'){
          this.options[item.id] = item.options?item.options.split(";"):[];
        }
      })
      this.formData = {};
      for(let k in group){
        group[k].map((item,index)=>{

          if(!this.formData[item.id]){
            this.$set(this.formData,item.id,{
              value:null,
              des:"",
              groupName:k,
              sort:index+1,
              fileList:[],
              name:item.name,
              type:item.type
            })

            this.$set(this.formDict3,item.id,{name:item.name,
              sort:index+1,
              id:item.id
            })
            this.$set(this.fileList,item.id,[])
          }
        })
      }
      console.log(this.formData,"formData")
      this.$set(this.formData,"check",{
        value:null,
        des:""
      })
      this.groupForm = group;

    },
    // 获取检查项
    getDictList(){
      this.$http({
        // url: "/zuul/cultural/check/dict",
       /* url: "/zuul/cultural/check/table/" + GlobalConfig.checkTableType,
        method: "get"*/
        url: "/zuul/cultural/check/table/raw",
        method: "get",
        params:{
          type:"yqfk"
        }
      }).then(res=>{
        if(res.data.success){
          res.data.data.map(item=>{
            this.dictJson[item.infoType] = item;
          })
        }
        this.parseDict();
        console.log(this.dictJson,"dictJosn")

      })
    },
    resetFormData(){
      console.log(this.formData," res et formData")

      for(let k in this.formData){
        this.$set(this.formData,k,{
          value:this.formData[k].type=='checkbox'?[]:null,
          des:"",
          fileList:[],
          groupName:this.formData[k].groupName,
          sort:this.formData[k].sort,
          name:this.formData[k].name,
          type:this.formData[k].type,
        });
        this.$set(this.fileList,k,[]);
      }

    },
    onSubmit(values){
      let rectify = [];
      let confirmForm = [];
      let problemList = [];
      let form = {};
      for(let k in values){
        let fileListStr = "";
        if(values[k]=="0"){
          let fileList = [];
          console.log(this.formData[k].fileList,this.formData[k].fileList.map(p=>p.url).join(","),"保存图片")
          fileListStr = this.formData[k].fileList.map(p=>p.url).join(",");
          /*this.fileList[k].map((pic,index)=>{
            console.log(k,"k",pic)
            if(pic.file){
              fileList.push({
                name: pic.file.name.replace(/image/,new Date().getTime() + k + "-"+  index ),
                content:pic.content
              })
            }

          })*/
          if(this.venueData.recordId){
            problemList.push({
              typeId:k,
              checkResult: this.formData[k].des,
              // fileList:JSON.stringify(fileList)
              fileList:fileList,
              recordId:this.venueData.recordId?this.venueData.recordId:""
            })
          }else{
            problemList.push({
              typeId:k,
              checkResult: this.formData[k].des,
              // fileList:JSON.stringify(fileList)
              fileList:fileList

            })
          }

          rectify.push(k);
          if(this.formDict1[k])confirmForm.push(this.formDict1[k])
          if(this.formDict2[k])confirmForm.push(this.formDict2[k])
          if(this.formDict3[k])confirmForm.push(this.formDict3[k])
        }
        form[k] = {
          ...this.formData[k],
          fileList:fileListStr
        }
      }
      if(rectify.length==0){
        this.venueData.status = 0;
      }else{
        this.venueData.status = 1;
      }
      this.venueData.rectify = rectify.join(",");
      // this.venueData.content = JSON.stringify(values);
      this.venueData.content = JSON.stringify(form);
      this.venueData.problemList = problemList;
      this.confirmForm = confirmForm;
      if(rectify.length>0){
        this.noticeShow = true;
        this.des = "有" + rectify.length + "需整改";
      }else{
        this.noticeShow = false
      }
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
      let d = this.getDistance(this.venue.lngLat[0],this.venue.lngLat[1],this.currentPos[0],this.currentPos[1]);
      if(d>(GlobalConfig.nearDistance+0.1)){
        Notify({ type: 'warning', message: "不在检查场所范围内，不能提交！" });
        return false;
      }
      console.log(d,this.venue.lngLat,this.currentPos)
      let nowTime = new Date().getTime();
      let timeGap = (nowTime - this.checkBegin)/1000;
      console.log(timeGap,this.checkedTime,this.checkBegin,"检查时间")
      if(timeGap<GlobalConfig.checkTime){
        Notify({ type: 'warning', message: '检查时间少于'+GlobalConfig.checkTime/60 + "分钟，不能提交！" });
        return false;
      }
      this.loading = true;
      this.show = true;
    },
    showPosition(position){
      this.currentPos = this.wgs84togcj02(position.coords.longitude,position.coords.latitude);
    },
    wgs84togcj02(lng, lat) {
      let x_PI = 3.14159265358979324 * 3000.0 / 180.0;
      let a = 6378245.0;
      let ee = 0.00669342162296594323;
      let PI = Math.PI;
      lat = +lat;
      lng = +lng;
      if (this.out_of_china(lng, lat)) {
        return [lng, lat]
      } else {
        var dlat = this.transformlat(lng - 105.0, lat - 35.0);
        var dlng = this.transformlng(lng - 105.0, lat - 35.0);
        var radlat = lat / 180.0 * PI;
        var magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        var sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
        var mglat = lat + dlat;
        var mglng = lng + dlng;
        return [mglng, mglat]
      }
    },
    transformlat(lng, lat) {
      let PI = Math.PI;
      lat = +lat;
      lng = +lng;
      var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
      ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
      return ret
    },

    transformlng(lng, lat) {
      let PI = Math.PI;
      lat = +lat;
      lng = +lng;
      var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
      ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
      return ret
    },

    /**
     * 判断是否在国内，不在国内则不做偏移
     * @param lng
     * @param lat
     * @returns {boolean}
     */
    out_of_china(lng, lat) {
      lat = +lat;
      lng = +lng;
      // 纬度3.86~53.55,经度73.66~135.05
      return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55);
    },
    getDistance( lat1,  lng1,  lat2,  lng2){
      var radLat1 = lat1*Math.PI / 180.0;
      var radLat2 = lat2*Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
          Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
      s = s *6378.137 ;// EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      return s;
    },
    validator(val,rule){
      console.log(val,rule,"validator");
      if(this.formData[rule.ruleId]["value"]=="0"){
        return val.trim()?true:false;
      }
      return true;
    },
    validatorPic(val,rule){
      console.log(val,rule,"validatorPic");
      if(this.formData[rule.ruleId]["value"]=="0"){
        return (Array.isArray(val)&&val.length>0)?true:false;
      }
      return true;
    },
    save(values){
      this.venueData.checkType = this.venue.checkType;
      let _this = this;
      this.show = true;
      this.temp = false;
      this.venueData.person = localStorage.getItem("wljptuser")
      if(this.pics.length>0){
        this.venueData.picture = this.pics.map(item=>{
          return item.url
        }).join(",");
      }
      let data = this.venueData;
      if(localStorage.getItem("wljptofficeIds")){
        data.officeIds = localStorage.getItem("wljptofficeIds");
      }
      if(this.venueData.recordId){
        data.id = this.venueData.recordId
      }
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:{
          ...data,
          type:this.groupType
        }
      }).then(res=>{
          if(res.data.success){
            _this.saveLastCheck({
              ...this.userInfo,
              latestCheckStatus:'0'
            })
            _this.checkData = res.data.data;
            this.successPop = true;
            this.seconds = 5;
            this.checkedTime = new Date().getTime();
            _this.timer = setInterval(() => {
              _this.seconds--;
              if(_this.seconds<0){
                _this.successPop = false;
                window.clearInterval(_this.timer);
              }
            }, 1000);
            _this.timeOuter = setTimeout(function(){
              _this.resetFormData();
              _this.$emit("closeReport")
            },5000);

          }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
         this.loading = false;
      })
    },
    saveLastCheck(data){
      this.$http({
        url: "/zuul/cultural/user" ,
        method: "post",
        data:data
      }).then(res=>{
        if (res.data.success) {
          console.log("更新企业检查状态成功");
        }
      }).catch(e=>{

      }).finally(e=>{
      })
    },
    failed(rule){
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    cancleConfirm(){
      this.show = false;
      this.loading = false;
    },
    confirm(){
      this.save();
    },
    init(){
      if(this.formDict3.length>0){
        this.pics = [];
        this.loading = false;
        this.show = false;
        this.successPop = false;
        this.venueData.checkedSign = "";
        this.venueData.checkerSign = "";
        this.hasTemp();
      }else{
        setTimeout(() => {
          this.pics = [];
          this.loading = false;
          this.show = false;
          this.successPop = false;
          this.venueData.checkerSign = "";
          this.venueData.checkedSign = "";
          this.hasTemp();
        }, 150)
      }

    },
    viewDetail(){
      this.$emit("viewDetail",{
        venue:this.venue,
        check:this.checkData
      })
    },
    tempSave(){
      let _this = this;
      this.$refs.checkForm.resetValidation();
      let problemList = [];
      let form = {};
      for(let k in this.formData){
        let fileListStr = "";
        if(this.formData[k]["value"]=="0"){
          let fileList = [];
          console.log(this.formData[k].fileList,this.formData[k].fileList.map(p=>p.url).join(","),"保存图片")
          fileListStr = this.formData[k].fileList.map(p=>p.url).join(",");
          /*this.fileList[k].map((pic,index)=>{
            if(pic.file) {
              fileList.push({
                name: pic.file.name.replace(/image/, new Date().getTime() + k + "-" + index),
                content: pic.content
              })
            }
          })*/
          /*problemList.push({
            typeId:k,
            checkResult: this.formData[k].des,
            fileList:fileList,
            recordId:this.venueData.recordId?this.venueData.recordId:""
          })*/
          if(this.venueData.recordId){
            problemList.push({
              typeId:k,
              checkResult: this.formData[k].des,
              fileList:fileList,
              recordId:this.venueData.recordId
            })
          }else{
            problemList.push({
              typeId:k,
              checkResult: this.formData[k].des,
              fileList:fileList
            })
          }
        }else{
          fileListStr = "";
        }
        form[k] = {
          ...this.formData[k],
          fileList:fileListStr
        }
      }
      this.venueData.content = JSON.stringify(form);
      this.venueData.problemList = problemList;
      this.venueData.status = -1;
      if(this.pics.length>0){
        this.venueData.picture = this.pics.map(item=>{
          return item.url
        }).join(",");
      }
      let data = this.venueData;
      if(this.venueData.recordId){
        data.id = this.venueData.recordId;
      }
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:{
          ...data,
          type:this.groupType
        }
      }).then(res=>{
        if(res.data.success){
          this.successPop = true;
          this.temp = true;
          this.seconds = 5;
          _this.timer = setInterval(() => {
            _this.seconds--;
            if(_this.seconds<0){
              _this.successPop = false;
              window.clearInterval(_this.timer);
            }
          }, 1000);
          _this.timeOuter = setTimeout(function(){
            _this.resetFormData();
            _this.$emit("closeReport")
          },5000);

        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{

      })
    },
    goToMap(){
      this.successPop = false;
      clearTimeout(this.timeOuter);
      this.resetFormData();
      this.$emit("closeReport")
    },
    hasTemp(){
      this.$refs.checkForm.resetValidation();
      this.checkedSigned = false;
      this.checkerSigned = false;
      this.$http({
        url: "/zuul/cultural/check/hasTemp/" + this.venue.id,
        method: "get",
        params:{
          type:this.groupType,
          person: localStorage.getItem('wljptuser')
        }
      }).then(res=>{
        console.log(res,"hasTemp");
        this.tempTime = "";
        if(res.data.success && res.data.data && res.data.data.content){
          // console.log("success", res.data.data.createTime,res.data.data.createTime.substr(0,16))
          this.tempTime ="暂存时间："+ res.data.data.createTime.substr(0,16);
          this.tempShow = true;
            this.venueData.recordId = res.data.data.id;
            let tempObj = {};
            let obj = JSON.parse(res.data.data.content);
            if(res.data.data.checkedSign){
              this.checkedSigned = true;
              this.venueData.checkedSign = res.data.data.checkedSign;
            }
            if(res.data.data.checkerSign){
              this.checkerSigned = true;
              this.venueData.checkerSign = res.data.data.checkerSign;
            }
          console.log(obj,"obj")
            for(let k in obj){
              console.log(obj[k],"k")

              if(obj[k].fileList){
                obj[k].fileList = obj[k].fileList.split(",").map(p=>{
                  return {
                    url:p,
                    isImage:true
                  }
                })
              }else{
                obj[k].fileList = [];
              }

              tempObj[k] = obj[k];
            }
            this.formData = tempObj;
            console.log(this.formData,"temp formData")
            if(res.data.data.problemList && res.data.data.problemList.length>0){
              res.data.data.problemList.map(item=>{
                let arr = [];
                if(item.recordFileList){
                  item.recordFileList.map(pic=>{
                    arr.push({
                      url:pic,
                      isImage: true,
                      id:item.id,
                      recordId:item.recordId,
                      checkResult:item.checkResult,
                      typeId:item.typeId
                    })
                  })
                }
                if(this.formData[item.typeId] && Array.isArray(this.formData[item.typeId].fileList)){
                  this.formData[item.typeId].fileList = this.formData[item.typeId].fileList.concat(arr)
                }

                this.fileList[item.typeId] =arr;
              })
            }
            this.pics = [];
            if(res.data.data.picture){
              let p = res.data.data.picture.split(",");
              p.map(item=>{
                this.pics.push({
                  url:item,
                  isImage:true
                })
              })
            }
        }else{
          this.venueData.recordId = "";
          this.tempShow = false;
          this.resetFormData();
        }
      }).catch(error=>{
        this.tempTime = "";
      })
    },
    beforeRead(file,defaultQuqlity=0.6) {
      let fileSize = file.size/1024/1024;
      let quality = defaultQuqlity;
      if(fileSize<0.5){
        quality = 1;
      }else if(fileSize<1){
        quality = 0.8
      }else if(fileSize<2){
        quality = 0.6
      }else if(fileSize<4){
        quality = 0.3   // 0.4 0.24
      }else if(fileSize<6){
        quality = 0.15  // 0.1 0.06   0.2-0.17
      }else if(fileSize<8.1){
        quality = 0.1
      }else{
        return new Promise((resolve,reject)=>{
          Toast('文件大小不能超过 8M');
          reject({
            msg:"图片不能超过6M"
          })

        })
      }

      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,
          success(result){
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    del(file){
      if(file.url){
        let url = file.url.replace('/cultural/view?name=','/cultural/remove?name=')
        this.$http({
          url: url,
          method: "delete"
        }).then(res=>{
          if(res.data.success){
            console.log("删除成功")
          }
        })
      }
    },
    afterRead(file,rule){
      let key = rule.name.split('-')[1];
      console.log(key,this.formData[key])
      console.log(this.formData[key].fileList,"上传文件");
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{
        if(res.data.success){
          let len = this.formData[key].fileList.length;
          this.formData[key].fileList[len>0?(len - 1):0].url = '/zuul' +res.data.data[0].viewUrl;
          console.log(this.formData[key],"fileList")
        }
      })
    },
    sendFile(file){
      console.log(file.file)
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
     /* formData.append("timed",true);
      formData.append("filename",new Date().getTime() + file.file.name);*/
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{
        if(res.data.success){
            this.pics[this.pics.length>0?(this.pics.length - 1):0].url = '/zuul' +res.data.data[0].viewUrl;
          }
      })
    },
    openSign(value){
      this.signer = value;
      this.signShow = true;
    },
    getSign(value){
      this.venueData[value.value] = value.content;
      if(value.value=='checkerSign'){
        this.checkerSigned = true;
      }else{
        this.checkedSigned = true;
      }
      this.signShow = false;
    }
  },
  mounted(){
    this.venueData.remark = localStorage.getItem("wljptuserName");
    this.venueData.person = localStorage.getItem("wljptuser");
    this.getDictList();
  },
  beforeDestroy() {
    if(this.timer){
      window.clearInterval(this.timer);
    }
    if(this.checkTimeTimer){
      window.clearInterval(this.checkTimeTimer);
    }
  },
}
</script>

<style scoped lang="less">
  .item-label{
    color: #646566;
    font-size: 14px;
    padding: 8px 12px 0px 24px;
  }
  .form-title{
    color: rgba(69, 90, 100, 0.6);
    line-height: 45px;
    padding: 5px 16px 0px;
    background: #f7f8fa;
  }
  .form-label{
    padding: 10px 12px 0px;
    color: #646566;
  }
  .label-tips{
    position: relative;
    //left: calc(-100vw + 152px);
    color: red;
    position: relative;
    top: 3px;
  }
  .confirm-item{
    padding: 5px 20px;
    color: #323233;
  }
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: center;
    line-height: 50px;
    position: relative;
    .van-icon{
      position: absolute;
      left: 5px;
      top: 10px;
      font-weight: normal;
      color: #969799;
    }
  }
  .success-icon{
    text-align: center;
    position: relative;
    top: 120px;
  }
  .success-content{
    padding: 150px 20px;
    text-align: center;
  }
  .temp-save-btn{
    display: inline-block;
    box-sizing: border-box;
    width:100% ;
    border: 1px solid #ebedf0;
    height: calc(100% - 2px);
    font-size: 16px;
    text-align: center;
    line-height:50px;
    background: #fff;
    //border-radius: 16px;
  }
  /deep/ .form-image .van-field__error-message{
    top: 60px;
    left:90px;
  }
  /deep/ .van-cell__title{
    padding-left: 8px;
  }
  /deep/ .van-radio-group--horizontal{
    width: 150px;
  }
  /deep/ .van-field__error-message{
    position: absolute;
    top: 1px;
    left: -23px;
  }
  /deep/ .van-dialog{
    width: calc(100vw - 20px);
  }
  /deep/ .van-dialog__header{
    padding-bottom: 10px;
  }
  /deep/ .van-dialog__footer{
    padding: 10px 0px;
  }
</style>
