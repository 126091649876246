import axios from 'axios';
//获取数据字典所有直接下级
export const RenderCheckDict = function (callback) {
    axios({
        url: "/zuul/cultural/check/table/" + GlobalConfig.checkTableType,
        method: 'get'
    }).then(result => {
        if(result.data.success){
            callback && callback(result.data.data)
        }
    })
};

//获取企业类型
export const RenderCulturalDict = function (callback) {
    axios({
        url: "/zuul/cultural/type/raw/",
        method: 'get'
    }).then(result => {
        if(result.data.success){
            callback && callback(result.data.data)
        }
    })
};

// 所属街道
export const StreetJson = {
    "3201050010":{
        code:'3201050010',
        name:"莫愁湖街道"
    },
    "3201050020":{
        code:'3201050020',
        name:"沙洲街道"
    },
    "3201050030":{
        code:'3201050030',
        name:"双闸街道"
    },
    "3201050040":{
        code:'3201050040',
        name:"江心洲街道"
    },
    "3201050060":{
        code:'3201050060',
        name:"兴隆街道"
    },
    "3201050070":{
        code:'3201050070',
        name:"南苑街道"
    }
}

//检查单位
export const OfficeJson = {
    "32010500":{
        "code": "32010500",
        "name": "局领导",
        "id": "32010500",
        "parentIdsList": ["320105"],
        "sort": 1,
        "type": "0004000200020000"
    },
    "32010501":{
        "code": "32010501",
        "name": "市场管理科",
        "id": "32010501",
        "parentIdsList": ["320105"],
        "sort": 2,
        "type": "0004000200020000"
    },
    "32010502":{
        "code": "32010502",
        "name": "旅游推广科",
        "id": "32010502",
        "parentIdsList": ["320105"],
        "sort": 3,
        "type": "0004000200020000"
    },
    "32010503":{
        "code": "32010503",
        "name": "党群文物科",
        "id": "32010503",
        "parentIdsList": ["320105"],
        "sort": 4,
        "type": "0004000200020000"
    },
    "32010504":{
        "code": "32010504",
        "name": "群众体育科",
        "id": "32010504",
        "parentIdsList": ["320105"],
        "sort": 5,
        "type": "0004000200020000"
    },
    "32010505":{
        "code": "32010505",
        "name": "群众文化科",
        "id": "32010505",
        "parentIdsList": ["320105"],
        "sort": 6,
        "type": "0004000200020000"
    },
    "32010506":{
        "code": "32010506",
        "name": "产业经济科",
        "id": "32010506",
        "parentIdsList": ["320105"],
        "sort": 7,
        "type": "0004000200020000"
    },
    "32010507":{
        "code": "32010507",
        "name": "政策法规科",
        "id": "32010507",
        "parentIdsList": ["320105"],
        "sort": 8,
        "type": "0004000200020000"
    },
    "32010508":{
        "code": "32010508",
        "name": "综合科",
        "id": "32010508",
        "parentIdsList": ["320105"],
        "sort": 9,
        "type": "0004000200020000"
    },
/*    "32010509":{
        "code": "32010509",
        "name": "直属单位",
        "id": "32010509",
        "parentIdsList": ["320105"],
        "sort": 10,
        "type": "0004000200020000"
    },*/
    "3201050901":{
        "code": "3201050901",
        "name": "图书馆",
        "id": "3201050901",
        "parentIdsList": ["320105"],
        "sort": 10,
        "type": "0004000200020000"
    },
    "3201050902":{
        "code": "3201050902",
        "name": "文化馆",
        "id": "3201050902",
        "parentIdsList": ["320105"],
        "sort": 11,
        "type": "0004000200020000"
    },
    "3201050903":{
        "code": "3201050903",
        "name": "业余体校",
        "id": "3201050903",
        "parentIdsList": ["320105"],
        "sort": 12,
        "type": "0004000200020000"
    },
    "32010510":{
        "code": "32010510",
        "name": "安全专家",
        "id": "32010510",
        "parentIdsList": ["320105"],
        "sort": 13,
        "type": "0004000200020000"
    },
    "32010511":{
        "code": "32010511",
        "name": "专项检查组",
        "id": "32010511",
        "parentIdsList": ["320105"],
        "sort": 13,
        "type": "0004000200020000"
    },
    "32010512":{
        "code": "32010512",
        "name": "南苑街道",
        "id": "32010512",
        "parentIdsList": ["320105"],
        "sort": 15,
        "type": "0004000200020000"
    },
    "32010513":{
        "code": "32010513",
            "name": "莫愁湖街道",
            "id": "32010513",
            "parentIdsList": ["320105"],
            "sort": 16,
            "type": "0004000200020000"
    },
    "32010514":{
        "code": "32010514",
            "name": "兴隆街道",
            "id": "32010514",
            "parentIdsList": ["320105"],
            "sort": 17,
            "type": "0004000200020000"
    },
    "32010515":{
        "code": "32010515",
            "name": "双闸街道",
            "id": "32010515",
            "parentIdsList": ["320105"],
            "sort": 18,
            "type": "0004000200020000"
    },
    "32010516":{
        "code": "32010516",
            "name": "沙洲街道",
            "id": "32010516",
            "parentIdsList": ["320105"],
            "sort": 19,
            "type": "0004000200020000"
    },
    "32010517":{
        "code": "32010517",
            "name": "江心洲街道",
            "id": "32010517",
            "parentIdsList": ["320105"],
            "sort": 20,
            "type": "0004000200020000"
    }
}

export const OfficeList = [
    {
        "code": "32010500",
        "name": "局领导",
        "id": "32010500",
        "parentIdsList": ["320105"],
        "sort": 1,
        "type": "0004000200020000"
    }, {
        "code": "32010501",
        "name": "市场管理科",
        "id": "32010501",
        "parentIdsList": ["320105"],
        "sort": 2,
        "type": "0004000200020000"
    }, {
        "code": "32010502",
        "name": "旅游推广科",
        "id": "32010502",
        "parentIdsList": ["320105"],
        "sort": 3,
        "type": "0004000200020000"
    }, {
        "code": "32010503",
        "name": "党群文物科",
        "id": "32010503",
        "parentIdsList": ["320105"],
        "sort": 4,
        "type": "0004000200020000"
    }, {
        "code": "32010504",
        "name": "群众体育科",
        "id": "32010504",
        "parentIdsList": ["320105"],
        "sort": 5,
        "type": "0004000200020000"
    }, {
        "code": "32010505",
        "name": "群众文化科",
        "id": "32010505",
        "parentIdsList": ["320105"],
        "sort": 6,
        "type": "0004000200020000"
    }, {
        "code": "32010506",
        "name": "产业经济科",
        "id": "32010506",
        "parentIdsList": ["320105"],
        "sort": 7,
        "type": "0004000200020000"
    }, {
        "code": "32010507",
        "name": "政策法规科",
        "id": "32010507",
        "parentIdsList": ["320105"],
        "sort": 8,
        "type": "0004000200020000"
    }, {
        "code": "32010508",
        "name": "综合科",
        "id": "32010508",
        "parentIdsList": ["320105"],
        "sort": 9,
        "type": "0004000200020000"
    },
    /*{
        "code": "32010509",
        "name": "直属单位",
        "id": "32010509",
        "parentIdsList": ["320105"],
        "sort": 10,
        "type": "0004000200020000"
    },*/
    {
        "code": "3201050901",
        "name": "图书馆",
        "id": "3201050901",
        "parentIdsList": ["320105"],
        "sort": 10,
        "type": "0004000200020000"
    }, {
        "code": "3201050902",
        "name": "文化馆",
        "id": "3201050902",
        "parentIdsList": ["320105"],
        "sort": 11,
        "type": "0004000200020000"
    }, {
        "code": "3201050903",
        "name": "业余体校",
        "id": "3201050903",
        "parentIdsList": ["320105"],
        "sort": 12,
        "type": "0004000200020000"
    },

    {
        "code": "32010510",
        "name": "安全专家",
        "id": "32010510",
        "parentIdsList": ["320105"],
        "sort": 13,
        "type": "0004000200020000"
    },

    {
        "code": "32010511",
        "name": "专项检查组",
        "id": "32010511",
        "parentIdsList": ["320105"],
        "sort": 13,
        "type": "0004000200020000"
    },
    {
        "code": "32010512",
        "name": "南苑街道",
        "id": "32010512",
        "parentIdsList": ["320105"],
        "sort": 15,
        "type": "0004000200020000"
    }, {
        "code": "32010513",
        "name": "莫愁湖街道",
        "id": "32010513",
        "parentIdsList": ["320105"],
        "sort": 16,
        "type": "0004000200020000"
    }, {
        "code": "32010514",
        "name": "兴隆街道",
        "id": "32010514",
        "parentIdsList": ["320105"],
        "sort": 17,
        "type": "0004000200020000"
    }, {
        "code": "32010515",
        "name": "双闸街道",
        "id": "32010515",
        "parentIdsList": ["320105"],
        "sort": 18,
        "type": "0004000200020000"
    }, {
        "code": "32010516",
        "name": "沙洲街道",
        "id": "32010516",
        "parentIdsList": ["320105"],
        "sort": 19,
        "type": "0004000200020000"
    }, {
        "code": "32010517",
        "name": "江心洲街道",
        "id": "32010517",
        "parentIdsList": ["320105"],
        "sort": 20,
        "type": "0004000200020000"
    }
]