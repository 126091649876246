<template>
  <div class="map">
    <map-z @on-map="getMap"></map-z>
    <div class="notice">
      <van-notice-bar
          scrollable
          left-icon="volume-o"
          :speed="40"
          :text="noticeStr"
      />
    </div>
    <div id="toolControl" style="display: none;">
<!--    <div id="toolControl" >-->
<!--      <div class="toolItem" :class="{active:(activeTool=='marker'?true:false)}" id="marker" title="点标记" @click="activeEditor('marker')"></div>-->
<!--      <div class="toolItem" :class="{active:(activeTool=='polyline'?true:false)}"  id="polyline" title="折线" @click="activeEditor('polyline')"></div>-->
      <div class="toolItem" :class="{active:(activeTool=='polygon'?true:false)}"  id="polygon" title="多边形" @click="activeEditor('polygon')"></div>
<!--      <div class="toolItem" :class="{active:(activeTool=='circle'?true:false)}"  id="circle" title="圆形" @click="activeEditor('circle')"></div>-->
    </div>
    <div class="layer-show" >
      <van-icon :name="layerStyle['0']['icon']" size="32"  @click="show=true" />
<!--          <van-button type="primary" size="normal" @click="destroy">remove</van-button>-->
    </div>
    <div class="maker-detail" v-if="visible">
      <div class="close-icon" @click="visible=false">×</div>
      <van-row>
        <van-col span="5" class="maker-img" @click="infoPopup=true">
          <img width="50" height="50" :src="makerDetail.src" alt="">
        </van-col>
        <van-col span="19">
          <div class="maker-name" @click="infoPopup=true">
            {{makerDetail.name}}【{{makerDetail.mark}}】

          </div>
          <div class="maker-address">{{makerDetail.address}}</div>
          <div class="maker-address">{{makerDetail.person}}&nbsp;&nbsp;&nbsp;&nbsp;{{makerDetail.contact}}&nbsp;<van-icon name="phone" color="#1989fa" /></div>
          <div>
            <van-cell center title="是否关停">
              <template #right-icon>
                <van-switch v-model="clickDevice.closed" size="24" @change="changeDeviceStatus" />
<!--                <van-switch :value="clickDevice.closed" @input="changeDeviceStatus" />-->
              </template>
            </van-cell>
          </div>
        </van-col>

      </van-row>
      <br>
      <van-row :gutter="16">
          <van-col span="8" class="col-left">
            <van-icon name="user-circle-o" />&nbsp;疫情防控
          </van-col>
          <van-col span="16" class="col-right">
                <span class="btn btn-left"  @click="showDetail">检查详情</span>
                <span class="btn btn-right" @click="report">进入检查</span>

          </van-col>
          <van-col span="8" class="col-left">
            <van-icon name="bullhorn-o" color="" />&nbsp;行业检查
          </van-col>
          <van-col span="16" class="col-right">
            <span class="btn btn-left" style="background: #3eabe1;"  @click="openCheck('aqsc')">检查详情</span>
            <span class="btn btn-right" style="background: #2d93c6;" @click="checkIn('aqsc')">进入检查</span>
          </van-col>
      </van-row>
<!--      <van-row :gutter="16">
        <van-col span="12">
          <van-button  size="large"  type="info" @click="showDetail">检查详情</van-button>
        </van-col>
        <van-col span="12">
          <van-button  size="large"  type="info" @click="report">进入检查</van-button>
        </van-col>
      </van-row>-->
    </div>
<!--    搜索框及辖区展示-->
    <div class="search-content" :class="{'whole-content':searchShow}">
      <div v-if="!searchShow" style="padding: 0px 0px 20px 0px;">
        <van-search @focus="searchShow=true" placeholder="请输入搜索关键词" />
      </div>
      <div v-else style="position: relative;">
        <van-search v-model="keyWords" placeholder="请输入搜索关键词"/>
        <div class="district-content">
          <van-row type="flex" justify="left">
            <van-col span="8" v-for="(item,index) in districtData.features" v-bind:key="'districtData'+item.id" @click="showDistrict(item.id,index)">
              <div class="district-icon">
                <div style="display: inline-block;width: 36px;height: 36px;line-height:45px;border-radius: 18px;box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100); ">
                <van-image
                    width="26"
                    height="26"
                    fit="contain"
                    :src="districtImg[item.styleId]"
                />
                </div>
              </div>
<!--                <van-icon name="smile" size="32"  :color="colors[index]?colors[index]:colors[0]" /></div>-->
              <div style="padding: 8px 0px;font-size: 14px;">{{item.properties.name}}</div>
            </van-col>
          </van-row>
        </div>
        <div class="search-list">
            <div v-for="item in filterLayerData" v-bind:key="'layerData'+item.id" class="list-item" @click="locateToItem(item)">
              <div class="list-img">
                <img width="25" height="25" :src="layerStyle[item.properties.type]['status'+ item.properties.status]" alt="">
              </div>
              <div class="list-name">
                <div>{{item.name}}</div>
                <div class="list-address">{{item.properties.address}}</div>
              </div>
            </div>
        </div>
      </div>

    </div>

<!--    图层控制按钮-->
    <van-popup v-model="show" position="right" :style="{ width: '70%',height:'100%' }" >
      <div class="layer-title">选择</div>
      <van-row type="flex" justify="center">
        <van-col span="6" v-for="(item,key,index) in buttonList" v-bind:key="'btn'+index">
          <div class="layer-icon button-icon" @click="filter(key)">
            <van-badge :content="buttonCount[key]" color="#1989fa">
              <van-image
                  width="64"
                  height="30"
                  fit="contain"
                  :src="item.checked?item.icon1:item.icon2"
              />
            </van-badge>
          </div>
        </van-col>
      </van-row>
      <div style="position: relative;padding-bottom: 16px;">
        <div class="layer-title" style="margin-top:10px;line-height: 2.5rem;">图层</div>
        <div style="display: inline-block;position:absolute;top: -5px;right: 0px;">
          <div :style="{width:'calc(70vw/3)',display: 'inline-block'}">
            <div class="layer-icon" @click="changeLayerShow('0')">
              <van-badge :content="layerCount['0']?layerCount['0']:0">
                <van-image
                    width="50px"
                    height="50px"
                    fit="contain"
                    :src="layerStyle['0'].show?layerStyle['0'].icon1:layerStyle['0'].icon2"
                />
              </van-badge>
              <div style="display: inline-block;vertical-align: top;line-height: 60px; ">全部</div>
            </div>
          </div>
        </div>
        <br>
        <div v-for="(item,key) in layerStyle" v-bind:key="'layer'+item.id" style="display: inline-block;">

          <div v-if="!streetIn.includes(item.id) && key!='0'" :style="{width:'calc(70vw/3)',display: 'inline-block'}">
            <div class="layer-icon" @click="changeLayerShow(item.id)">
              <van-badge :content="layerCount[item.id]?layerCount[item.id]:0">
                <van-image
                    width="50px"
                    height="50px"
                    fit="contain"
                    :src="item.show?item.icon1:item.icon2"
                />
              </van-badge>
            </div>
            <div class="layer-name">{{item.name}}</div>
          </div>
        </div>
        <div class="layer-title" style="margin-top:10px;">街道主责</div>
        <div v-for="item in layerStyle" v-bind:key="'street'+item.id" style="display: inline-block;">
          <div v-if="streetIn.includes(item.id)" style="width:calc(70vw/3);display: inline-block;">
            <div class="layer-icon" @click="changeLayerShow(item.id)">
              <van-badge :content="layerCount[item.id]?layerCount[item.id]:0">
                <van-image
                    width="50px"
                    height="50px"
                    fit="contain"
                    :src="item.show?item.icon1:item.icon2"
                />
              </van-badge>
            </div>
            <div class="layer-name">{{item.name}}</div>
          </div>
        </div>
      </div>

<!--      <van-row type="flex" justify="left">
          <van-col span="8" v-for="item in layerStyle" v-bind:key="item.id"  v-if="!streetIn.includes(item.id)">
            <div class="layer-icon" @click="changeLayerShow(item.id)">
              <van-badge :content="layerCount[item.id]?layerCount[item.id]:0">
                <van-image
                    width="50px"
                    height="50px"
                    fit="contain"
                    :src="item.show?item.icon1:item.icon2"
                />
              </van-badge>
            </div>
            <div class="layer-name">{{item.name}}</div>
          </van-col>

      </van-row>-->
    </van-popup>
<!--    检查项弹窗-->
    <van-popup v-model="reportPopup" position="right" :style="{ height: '100%',width:'100%' }">
        <div class="model-title"><van-icon name="arrow-left" size="28" @click="reportPopup=false" />疫情防控检查上报</div>
        <report :venue="makerDetail" ref="report" @closeReport="closeReport" @viewDetail="viewDetail"></report>
    </van-popup>
<!--    检查列表弹窗-->
    <van-popup v-model="reportDetailPopup" position="right" :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="reportDetailPopup=false" />
       检查记录
      </div>
      <list :venue="makerDetail" ref="checkList"  @viewDetail="viewDetail"></list>
    </van-popup>
<!--    每条纪录弹窗-->
    <van-popup v-model="recordPopup" position="right" :style="{ height: '100%',width:'100%' }" get-container="#app" >
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="recordPopup=false" />检查详情</div>
        <table-item :recordData="recordData"></table-item>
<!--      <record :recordData="recordData" ref="record"></record>-->
    </van-popup>
    <van-popup v-model="dialogShow" :round="true" style="width: calc(80vw);">

      <div class="dialog-content">
        <div class="dialog-title">{{dialogTitle}}</div>
        <van-field
            v-model="dialogRemark"
            rows="1"
            autosize
            label="描述"
            label-width="40"
            type="textarea"
            placeholder="请输入"
        />
      </div>

      <div>
        <van-row>
          <van-col span="12">
            <van-button type="default" hairline block @click="cancleDialog">取消</van-button>
          </van-col>
          <van-col span="12">
            <van-button type="default" hairline block @click="confirm"  :loading="confirmLoading">确认</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>

<!--    行业检查和文明城市创建检查上报-->
    <van-popup v-model="checkPopup" position="right" :style="{ height: '100%',width:'100%' }">
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="checkPopup=false" />{{chectTittle}}</div>
      <check :venue="makerDetail" ref="check" @closeReport="closeReport" :table="checkTable" @viewDetail="viewDetail"></check>
    </van-popup>

    <!-- 行业检查和文明城市创建检查记录 -->
    <van-popup v-model="reportListPopup" position="right" :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="reportListPopup=false" />
        检查记录
      </div>
      <lists :venue="recordList"  ref="checkLists"  @viewDetail1="viewDetail1"></lists>
    </van-popup>

<!--    行业检查单条记录-->
    <van-popup v-model="recordPopup1" position="right" :style="{ height: '100%',width:'100%' }" get-container="#app" >
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="recordPopup1=false" />检查详情</div>
      <table-items :recordData="recordData"></table-items>
    </van-popup>

<!--    企业档案信息-->
    <van-popup v-model="infoPopup" position="right" :style="{ height: '100%',width:'100%' }" get-container="#app" >
      <div class="model-title"><van-icon name="arrow-left" size="28" @click="infoPopup=false" />企业档案</div>
      <info :id="makerDetail.id"></info>
    </van-popup>
  </div>
</template>

<script>
import {
 Button, Col, Row,Popup,Image,Icon,Search,Badge,NoticeBar,Switch,Cell,Field,Notify
} from 'vant';
import mapZ from "@/view/Tmap/index"
import report from "./components/report"
import check from "./components/check"
import tableItem from "./components/tableItem"
import tableItems from "./components/tableItems"
import list from "./components/list"
import lists from "./components/lists"
import info from "./components/companyInfo"
import districtData from './district/district.json' //街道数据

export default {
  components:{ mapZ ,report,list,tableItem,check,lists,tableItems,info,
    [Search.name]: Search,
    [Button.name]: Button,
    [Popup.name]: Popup ,
    [Icon.name]: Icon ,
    [Image.name]: Image ,
    [Badge.name]: Badge ,
    [NoticeBar.name]: NoticeBar ,
    [Switch.name]: Switch ,
    [Cell.name]: Cell ,
    [Field.name]: Field ,
    [Notify.name]: Notify ,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data(){
    return {
      districtData,
      map:"",
      searchShow:false,
      dialogShow:false,
      confirmLoading:false,
      dialogTitle:"确认更改企业关停状态？",
      dialogRemark:"",
      visible:false,
      reportPopup:false,
      recordPopup1:false,
      infoPopup:false,
      reportDetailPopup:false,
      reportListPopup:false,
      recordList:{},
      recordPopup:false,
      show:false,
      districtImg:{
        default:require('./img/district/1.png'),
        default1:require('./img/district/2.png'),
        default2:require('./img/district/1.png'),
        default3:require('./img/district/2.png'),
        default4:require('./img/district/1.png'),
        default5:require('./img/district/2.png'),
      },
      buttonList:{
        "0":{
          icon1:require('./img/button/checked-on.svg'),
          icon2:require('./img/button/checked-off.svg'),
          checked:true
        },//合格
        "2":{
          icon1:require('./img/button/rectify-on.svg'),
          icon2:require('./img/button/rectify-off.svg'),
          checked:true
        },//整改
        "1":{
          icon1:require('./img/button/unchecked-on.svg'),
          icon2:require('./img/button/unchecked-off.svg'),
          checked:true
        },//未检
        "4":{
          icon1:require('./img/button/stop-on.svg'),
          icon2:require('./img/button/stop-off.svg'),
          checked:true
        }//关停
      },
      buttonCount:{},
      layerCount:{},
      layerStyle:{
        "0":{
          id:"0",
          icon:require('./img/button/0all.svg'),
          icon2:require('./img/button/0all-off.svg'),
          icon1:require('./img/button/0all-on.svg'),
          width:32,
          height:32,
          name:"全部",
          show:true
        },
        "1":{
          id:"1",
          icon2:require('./img/button/1netbar-off.svg'),
          icon1:require('./img/button/1netbar-on.svg'),
          status0:require('./img/icon/1netbar0.png'),//0合格
          status1:require('./img/icon/1netbar1.png'),//1未检查
          status2:require('./img/icon/1netbar2.png'),//2需整改
          status4:require('./img/icon/1netbar4.png'),//-1关停
          width:32,
          height:32,
          name:"网吧",
          show:true
        },
        "2":{
          id:"2",
          status0:require('./img/icon/2swimmingpool0.png'),//合格
          status1:require('./img/icon/2swimmingpool1.png'),//需整改
          status2:require('./img/icon/2swimmingpool2.png'),//未检查
          status4:require('./img/icon/2swimmingpool4.png'),//未检查
          icon2:require('./img/button/2swimmingpool-off.svg'),
          icon1:require('./img/button/2swimmingpool-on.svg'),
          width:32,
          height:32,
          name:"游泳馆",
          show:true
        },
        "3":{
          id:"3",
          icon2:require('./img/button/3KTV-off.svg'),
          icon1:require('./img/button/3KTV-on.svg'),
     /*     status0:require('./img/icon/3KTV0.png'),//合格
          status1:require('./img/icon/3KTV1.png'),//需整改
          status2:require('./img/icon/3KTV2.png'),//未检查
          status4:require('./img/icon/3KTV4.png'),//未检查*/
          status0:require('./img/icon/3entertain0.png'),//合格
          status1:require('./img/icon/3entertain1.png'),//需整改
          status2:require('./img/icon/3entertain2.png'),//未检查
          status4:require('./img/icon/3entertain4.png'),//未检查
          width:32,
          height:32,
          name:"娱乐场所",
          show:true
        },
        "4":{
          id:"4",
          status0:require('./img/icon/4performance0.png'),//合格
          status1:require('./img/icon/4performance1.png'),//需整改
          status2:require('./img/icon/4performance2.png'),//未检查
          status4:require('./img/icon/4performance4.png'),//未检查
          icon2:require('./img/button/4performance-off.svg'),
          icon1:require('./img/button/4performance-on.svg'),
          width:32,
          height:32,
          name:"演出场所",
          show:true
        },
        "5":{
          id:"5",
          status0:require('./img/icon/5travelAgentcy0.png'),//合格
          status1:require('./img/icon/5travelAgentcy1.png'),//需整改
          status2:require('./img/icon/5travelagentcy2.png'),//未检查
          status4:require('./img/icon/5travelagentcy4.png'),//未检查
          icon2:require('./img/button/5travelAgentcy-off.svg'),
          icon1:require('./img/button/5travelAgentcy-on.svg'),
          width:32,
          height:32,
          name:"旅行社",
          show:true
        },
        "6":{
          id:"6",
          icon2:require('./img/button/6venues-off.svg'),
          icon1:require('./img/button/6venues-on.svg'),
          status0:require('./img/icon/6venues0.png'),//合格
          status1:require('./img/icon/6venues1.png'),//需整改
          status2:require('./img/icon/6venues2.png'),//未检查
          status4:require('./img/icon/6venues4.png'),//未检查
          width:32,
          height:32,
          name:"文博场馆",
          show:true
        },
        "7":{
          id:"7",
          status0:require('./img/icon/74Ascenic0.png'),//合格
          status1:require('./img/icon/74Ascenic1.png'),//需整改
          status2:require('./img/icon/74Ascenic2.png'),//未检查
          status4:require('./img/icon/74Ascenic4.png'),//未检查
          icon2:require('./img/button/74Ascenic-off.svg'),
          icon1:require('./img/button/74Ascenic-on.svg'),
          width:32,
          height:32,
          name:"景区景点",
          show:true
        },
        /*"7":{
          id:"7",
          status0:require('./img/icon/74Ascenic0.png'),//合格
          status1:require('./img/icon/74Ascenic1.png'),//需整改
          status2:require('./img/icon/74Ascenic2.png'),//未检查
          status4:require('./img/icon/74Ascenic4.png'),//未检查
          icon2:require('./img/button/74Ascenic-off.svg'),
          icon1:require('./img/button/74Ascenic-on.svg'),
          width:32,
          height:32,
          name:"4A景区景点",
          show:true
        },
        "8":{
          id:"8",
          icon2:require('./img/button/83Ascenic-off.svg'),
          icon1:require('./img/button/83Ascenic-on.svg'),
          status0:require('./img/icon/83Ascenic0.png'),//合格
          status1:require('./img/icon/83Ascenic1.png'),//需整改
          status2:require('./img/icon/83Ascenic2.png'),//未检查
          status4:require('./img/icon/83Ascenic4.png'),//未检查
          width:32,
          height:32,
          name:"3A景区景点",
          show:true
        },*/

        "10":{
          id:"10",
          status0:require('./img/icon/10culturalheritage0.png'),//合格
          status1:require('./img/icon/10culturalheritage1.png'),//需整改
          status2:require('./img/icon/10culturalheritage2.png'),//未检查
          status4:require('./img/icon/10culturalheritage4.png'),//未检查
          icon2:require('./img/button/10culturalheritage-off.svg'),
          icon1:require('./img/button/10culturalheritage-on.svg'),
          width:32,
          height:32,
          name:"文体民非",
          show:true
        },
        "11":{
          id:"11",
          status0:require('./img/icon/11luxuryhotel0.png'),//合格
          status1:require('./img/icon/11luxuryhotel1.png'),//需整改
          status2:require('./img/icon/11luxuryhotel2.png'),//未检查
          status4:require('./img/icon/11luxuryhotel4.png'),//未检查
          icon2:require('./img/button/11luxuryhotel-off.svg'),
          icon1:require('./img/button/11luxuryhotel-on.svg'),
          width:32,
          height:32,
          name:"高等级酒店",
          show:true
        },

        "13":{
          id:"13",
          status0:require('./img/icon/13culturalcentre0.png'),//合格
          status1:require('./img/icon/13culturalcentre1.png'),//需整改
          status2:require('./img/icon/13culturalcentre2.png'),//未检查
          status4:require('./img/icon/13culturalcentre4.png'),//未检查
          icon2:require('./img/button/13culturalcentre-off.svg'),
          icon1:require('./img/button/13culturalcentre-on.svg'),
          width:32,
          height:32,
          name:"文化馆",
          show:true
        },
        "14":{
          id:"14",
          icon2:require('./img/button/14library-off.svg'),
          icon1:require('./img/button/14library-on.svg'),
          status0:require('./img/icon/14library0.png'),//合格
          status1:require('./img/icon/14library1.png'),//需整改
          status2:require('./img/icon/14library2.png'),//未检查
          status4:require('./img/icon/14library4.png'),//未检查
          width:32,
          height:32,
          name:"图书馆",
          show:true
        },
        "15":{
          id:"15",
          status0:require('./img/icon/15amateurschool0.png'),//合格
          status1:require('./img/icon/15amateurschool1.png'),//需整改
          status2:require('./img/icon/15amateurschool2.png'),//未检查
          status4:require('./img/icon/15amateurschool4.png'),//未检查
          icon2:require('./img/button/15amateurschool-off.svg'),
          icon1:require('./img/button/15amateurschool-on.svg'),
          width:32,
          height:32,
          name:"业余体校",
          show:true
        },
        "999":{
          id:"999",
          status0:require('./img/icon/9994.png'),//合格
          status1:require('./img/icon/9994.png'),//需整改
          status2:require('./img/icon/9994.png'),//未检查
          status4:require('./img/icon/9994.png'),//未检查
          icon2:require('./img/button/999-off.svg'),
          icon1:require('./img/button/999-on.svg'),
          width:32,
          height:32,
          name:"歇业场所",
          show:true
        },
        "9":{
          id:"9",
          status0:require('./img/icon/9fitness0.png'),//合格
          status1:require('./img/icon/9fitness1.png'),//需整改
          status2:require('./img/icon/9fitness2.png'),//未检查
          status4:require('./img/icon/9fitness4.png'),//未检查
          icon2:require('./img/button/9fitness-off.svg'),
          icon1:require('./img/button/9Fitness-on.svg'),
          width:32,
          height:32,
          name:"健身场馆",
          show:true
        },
        "12":{
          id:"12",
          icon2:require('./img/button/12hotel-off.svg'),
          icon1:require('./img/button/12hotel-on.svg'),
          status0:require('./img/icon/12hotel0.png'),//合格
          status1:require('./img/icon/12hotel1.png'),//需整改
          status2:require('./img/icon/12hotel2.png'),//未检查
          status4:require('./img/icon/12hotel4.png'),//未检查
          width:32,
          height:32,
          name:"一般酒店",
          show:true
        },
        "17":{
          id:"17",
          status0:require('./img/icon/17wenpei0.png'),//合格
          status1:require('./img/icon/17wenpei1.png'),//需整改
          status2:require('./img/icon/17wenpei2.png'),//未检查
          status4:require('./img/icon/17wenpei4.png'),//未检查
          icon2:require('./img/button/17wenpei-off.svg'),
          icon1:require('./img/button/17wenpei-on.svg'),
          width:32,
          height:32,
          name:"文艺培训",
          show:true
        },
        "18":{
          id:"18",
          status0:require('./img/icon/18tipei0.png'),//合格
          status1:require('./img/icon/18tipei1.png'),//需整改
          status2:require('./img/icon/18tipei2.png'),//未检查
          status4:require('./img/icon/18tipei4.png'),//未检查
          icon2:require('./img/button/18tipei-off.svg'),
          icon1:require('./img/button/18tipei-on.svg'),
          width:32,
          height:32,
          name:"体育培训",
          show:true
        },
        "19":{
          id:"19",
          status0:require('./img/icon/19majiang0.png'),//合格
          status1:require('./img/icon/19majiang1.png'),//需整改
          status2:require('./img/icon/19majiang2.png'),//未检查
          status4:require('./img/icon/19majiang4.png'),//未检查
          icon2:require('./img/button/19majiang-off.svg'),
          icon1:require('./img/button/19majiang-on.svg'),
          width:32,
          height:32,
          name:"棋牌(麻将)",
          show:true
        },
        "20":{
          id:"20",
          status0:require('./img/icon/16other0.png'),//合格
          status1:require('./img/icon/16other1.png'),//需整改
          status2:require('./img/icon/16other2.png'),//未检查
          status4:require('./img/icon/16other4.png'),//未检查
          icon2:require('./img/button/16other-off.svg'),
          icon1:require('./img/button/16other-on.svg'),
          width:32,
          height:32,
          name:"其它",
          show:true
        }
      },
      streetIn:['20','19','18','17','12','9'],
      clickDevice:{},
      makerDetail:{
        src:require('./img/icon/9fitness0.png'),
        name:"网络",
        address:"长江路长江路长江路长江路长江路长江路长江路长江路长江路长江路长江路长江路",
        type:"",
        person:"",
        contact:"",
        checkType:'0'
      },
      layer:{},
      //测试数据
      layerData1:[
        [{
          "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": '1',  //指定样式id
          "lngLat":[118.81556749353571,31.923362852851497],  //点标记坐标位置
          "properties": {//自定义属性
            "name": "3A景区1",
            "type":"网吧"
          }
        }, {//第二个点标记
          "id": "2",
          "styleId": '1',
          "lngLat":[118.81556749353571,31.93362852851497],
          "properties": {
            "name": "3A景区2",
            "type":"网吧"
          }
        }],
        [{
          "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": '2',  //指定样式id
          "lngLat":[118.84556749353571,31.933362852851497],  //点标记坐标位置
          "properties": {//自定义属性
            "name": "游泳池1",
            "type":"游泳池"
          }
        }, {//第二个点标记
          "id": "2",
          "styleId": '2',
          "lngLat":[118.82556749353571,31.93362852851497],
          "properties": {
            "name": "游泳池2",
            "type":"游泳池"
          }
        }]
      ],
      recordData:{},
      activeTool:"polygon",
      keyWords:"",
      filterLayerData:[],
      allLayerData:[],
      colors:["#0089BA","#FFC75F"],
      noticeStr:"未定位",
      choosenLayer:"",
      checkPopup:false,
      chectTittle:"行业检查上报",
      checkTable:{}
    }
  },
  methods:{
    getMap(map){
      this.map = map;
      this.showEditor();
      this.getLayerData();
      this.renderDistrictData();
      // this.map.addFeature("1",{
      //   style:this.layerStyle["1"],
      //   data:this.layerData1[0]
      // })
      // this.map.makerClickEvent("1",this.layerClickEvent);
      // this.map.addFeature("2",{
      //   style:this.layerStyle["2"],
      //   data:this.layerData1[1]
      // })
      // this.map.makerClickEvent("2",this.layerClickEvent);
    },
    // 加载辖区数据及名称
    renderDistrictData(){
      this.districtData.features.map(item=>{
         let district = {
             style:[
               {id:"default","color": "rgba(255,192,0,0.2)","borderColor": "#F8D800"},
               {id:"default2","color": "rgba(0, 4, 240,0.2)","borderColor": "rgba(0, 4, 240,1)"},
               {id:"default3","color": "rgba(255, 0, 0,0.2)","borderColor": "rgba(255, 0, 0,1)"},
               {id:"default4","color": "rgba(172, 0, 255,0.2)","borderColor": "rgba(172, 0, 255,1)"},
               {id:"default5","color": "rgba(3, 106, 76,0.2)","borderColor": "rgba(3, 106, 76,1)"},
               {id:"default1","color": "rgba(0,268,15,0.2)",borderColor:"rgba(0,268,15,1)"}
             ],
           data:[item]
         }
        this.map.addPolygon(item.id,district)
        this.map.polygonClickEvent(item.id,this.districtClick);

        this.map.addLabel(item.id,{
          style:{
            id:"districeLabel",
            color:"#C34A36"
          },
          data:[Object.assign(item.properties,{
            content:item.properties.name
          })]
        })
      })
      // let option = {
      //   style:[
      //     {id:"default"},
      //     {id:"default1",borderColor:"rgba(255,123,0,1)"}
      //   ],
      //   data:this.districtData.features
      // }
      // this.map.addPolygon("district",option)
      // this.map.polygonClickEvent("district",this.districtClick);
    },
    //显示隐藏辖区数据
    showDistrict(id,index){
     /* this.map.toggoleLayer("label",id);
      this.map.toggoleLayer("polygon",id);*/
      if(this.map.isLayerShow("label",id)){
        this.map.locatedTo(this.districtData.features[index].properties.lngLat)
      }
      //this.districtData.features[index].properties.lngLat
    },
    districtClick(g){
      console.log("istrict",g);
    },

    //获取图层数据
    getLayerData(){
      for(let k in this.layerStyle){
        for(let j in this.buttonList){
          this.map.deleteLayerData(k + "-" + j);
        }
      }
      this.layer = {};
      this.$http({
        url: "/zuul/cultural/layer",
        method: "get",
        params:{
          // officeId:localStorage.getItem("wljptofficeIds")
        }
      }).then(res=>{
        console.log(res,"res")
        this.layerCount = {};
        this.buttonCount = {};
        let n = 0;
        let allButton = {};
        if(res.data.success){
          res.data.data.map((item,index)=>{
            /*if(index%4==1){
              item.status = "0";
            }else if(index%4==2){
              item.status = "1";
            }else if(index%4==3){
              item.status = "2";
            }else{
              item.status = "4";
            }*/
            if(!item.type){
              item.type = '20';
            }
            // 场所状态: -1关停 0正常 1待检查 2待整改
            if(!item.status){
              item.status = "0";
            }
            if(item.status=="-1" || item.closed){
              item.status = "4";
            }
            if(!allButton[item.status]){
              allButton[item.status] = 1;
            }else{
              allButton[item.status]++;
            }
            this.allLayerData.push({
              "id": item.id,   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
              "styleId": item.type+ "-" + item.status,  //指定样式id
              "lngLat":item.position,  //点标记坐标位置
              "name": item.name,
              "properties": {//自定义属性
                "name": item.name,
                "address":item.address,
                "status":item.status,
                "type":item.type
              }
            })
            if(item.position){
                if(!this.buttonCount[item.status]){
                  this.buttonCount[item.status] = 1;
                }else{
                  this.buttonCount[item.status]++;
                }
                if(!item.type){item.type="20"}
                if(!this.layerCount["0"]){
                  this.layerCount["0"] = 1;
                }else {
                  this.layerCount["0"]++;
                }
                if(!this.layerCount[item.type]){
                  this.layerCount[item.type] = 1;
                }else {
                  this.layerCount[item.type]++;
                }
                let statusIcon = "0";
                if(!this.layer[item.type+ "-" + item.status]){
                  this.layer[item.type+ "-" + item.status] = [];
                }
                this.layer[item.type+ "-" + item.status].push({
                  "id": item.id,   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                  "styleId": item.type+ "-" + item.status,  //指定样式id
                  "lngLat":item.position,  //点标记坐标位置
                  "properties": {//自定义属性
                    "name": item.name,
                    "address":item.address,
                    "status":item.status,
                    "type":item.type
                  }
                })
            }else{
              n++;
            }
          })
          this.renderLayers();
        }
        if(n>0){
          this.noticeStr = n + "家企业未定位"
        }
        for(let m in allButton){
          this.noticeStr += "，" + this.statusLabel(m) + ":" + allButton[m] + "家";
        }
        this.noticeStr += "。";
        console.log("layercount",this.layerCount,this.buttonCount);

      })
    },
    // 渲染图层
    renderLayers(){
      for(let k in this.layer){
        this.map.addFeature(k,{
          style:{
            id:k,
            width:this.layerStyle[k.split("-")[0]].width,
            height:this.layerStyle[k.split("-")[0]].height,
            icon:this.layerStyle[k.split("-")[0]]["status" +k.split("-")[1]]
          },
          data:this.layer[k]
        })
        this.map.makerClickEvent(k,this.layerClickEvent);
      }
    },
    toggleFeature(){
      this.map.toggleFeature("1")
    },
    showLayer(){
      this.map.showFeature("1")
    },
    layerClickEvent(evt){
      console.log(evt,"evt")
      // console.log("222",evt.geometry,evt.geometry.properties.type);
      let maker = evt.geometry;
      this.choosenLayer = evt.geometry.styleId;
      // console.log(this.layerStyle[maker.properties.type]);
      this.makerDetail.src = this.layerStyle[maker.properties.type]["status"+maker.properties.status];
      this.makerDetail.name = maker.properties.name;
      this.makerDetail.mark = this.statusLabel(maker.properties.status);
      this.makerDetail.address = maker.properties.address;
      this.makerDetail.id = maker.id;
      this.visible = true;
      this.$http({
        url: "/zuul/cultural/" + maker.id,
        method: "get"
      }).then(res=>{
        if(res.data.success){
          this.clickDevice = res.data.data;
          this.dialogRemark = "";
          this.dialogTitle = "确认更改" + this.clickDevice.name + "的关停状态？"
          if(this.clickDevice)
          this.makerDetail.person = res.data.data.director?res.data.data.director:res.data.data.legalPerson;
          this.makerDetail.contact = res.data.data.contact1?res.data.data.contact1:res.data.data.legalPersonPhone;
        }
      })
    },
    statusLabel(status){
      let str = "";
      switch (status){
        case "0":
          str= "合格";
          break;
        case "1":
          str= "待检查";
          break;
        case "2":
          str= "待整改";
          break;
        case "4":
          str= "关停";
          break;
      }
      return str;
    },
    // 判断选中图层的数量
    changeButtonCount(layer){
      this.buttonCount = {};
      for(let k in layer){
        if(k==="0"){
           console.log(333);
        }else{
          for(let j in this.buttonList){
            if(layer[k].show){
              if(!this.buttonCount[j]){
               let n = 0;
               if(this.layer[k + "-" + j]){
                 n = this.layer[k + "-" + j].length;
               }
                this.buttonCount[j] = n;
              }else{
                let n = 0;
                if(this.layer[k + "-" + j]){
                  n = this.layer[k + "-" + j].length;
                }
                this.buttonCount[j] += n;
              }
            }
          }
        }
      }
    },
    changeLayerShow(id){
      this.layerStyle[id].show = !this.layerStyle[id].show;
      if(id==="0"){
        for(let k in this.layerStyle){
          this.layerStyle[k].show = this.layerStyle[id].show;
          for(let j in this.buttonList){
            if(this.buttonList[j].checked){
              if(this.layerStyle[id].show){
                this.map.showFeature(k + "-" + j);
              }else{
                this.map.hideFeature(k + "-" + j);
              }
            }
          }
        }
      }else{
        for(let j in this.buttonList){
          if(this.buttonList[j].checked){
            this.map.toggleFeature(id + "-" + j);
            let checkAll = this.isCheckAll();
            if(this.layerStyle[0].show!=checkAll){
              this.layerStyle[0].show = checkAll;
            }
          }
        }
      }
    },
    // 判断图层是否全部选中
    isCheckAll(){
      for(let k in this.layerStyle){
        if(k!="0"){
          if(!this.layerStyle[k].show){
            return false;
          }
        }
      }
      return true;
    },
    filter(key){
      this.buttonList[key].checked = !this.buttonList[key].checked;
      for(let k in this.layerStyle){
        if(this.layerStyle[k].show){
          if(this.buttonList[key].checked){
            this.map.showFeature(k +"-" + key)
          }else{
            this.map.hideFeature(k + "-" + key)
          }
        }
      }
    },
    destroy(){
      this.map.destroy();
    },
    showDetail(){
      this.reportDetailPopup=true;
      this.$nextTick(()=>{
        this.$refs.checkList.getList();
      })
    },
    showEditor(){
      // this.map.editor(["marker","polyline","polygon","circle"]);
      this.map.editor(["polygon"]);
      this.map.activeEditor("marker",this.editorPolygon)
    },
    editorPolygon(geometry){
      console.log(geometry,"geometry");
    },
    activeEditor(editorId){
      this.activeTool = editorId;
      this.map.activeEditor(editorId);
    },
    report(){
      this.reportPopup=true;
      this.$nextTick(()=>{
        this.$refs.report.init();
      })
    },
    closeReport(){
      // this.getLayerData();
      this.$http({
        url: "/zuul/cultural/" + this.makerDetail.id,
        method: "get"
      }).then(res=>{
        if(res.data.success){

          this.visible = false;


          this.visible = true;
          if(!res.data.data.status){
            res.data.data.status= "0";
          }
          if(res.data.data.status=="-1"){
            res.data.data.status = "4";
          }
          this.makerDetail.mark = this.statusLabel(res.data.data.status);
          if(!res.data.data.type){res.data.data.type="16"}
          let item = {
            "status":res.data.data.status,
            "type":res.data.data.type,
            "id": res.data.data.id,   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            "styleId": res.data.data.type+ "-" + res.data.data.status,  //指定样式id
            "lngLat":res.data.data.position,  //点标记坐标位置
            "position":res.data.data.position,  //点标记坐标位置
            "properties": {//自定义属性
              "name": res.data.data.name,
              "address":res.data.data.address,
              "status":res.data.data.status,
              "type":res.data.data.type
            }
          }
          console.log(item,"item",this.choosenLayer)
          this.map.delFeatureById(this.choosenLayer,res.data.data.id);
          if(this.map.isExistLayer(item.type + "-" + item.status)){
            this.map.addLayerFeature(item.type + "-" + item.status,item);
          }else{
            this.map.addFeature(item.type + "-" + item.status,{
              style:{
                id:item.type + "-" + item.status,
                width:this.layerStyle[item.type].width,
                height:this.layerStyle[item.type].height,
                icon:this.layerStyle[item.type]["status" +item.status]
              },
              data:[item]
            })
            this.map.makerClickEvent(item.type + "-" + item.status,this.layerClickEvent);
          }
        }
      })
      this.reportPopup = false;
      if(this.checkPopup){
        this.checkPopup = false;
      }
    },
    viewDetail(data){
      console.log(data,"checkData");
      this.recordData = data;
      this.recordPopup = true;
    },
    viewDetail1(data){
      console.log(data,"checkData");
      this.recordData = data;
      this.recordPopup1 = true;
    },
    listResolve() {
      let arr = [];
      if (this.keyWords.length > 0) {
        arr = this.allLayerData.filter(item => {
          if (item["name"] && item["name"].indexOf(this.keyWords) > -1) {
            return item
          }
        })
      }
      this.filterLayerData = arr;
    },
    locateToItem(item){
      if(item.lngLat.length>0){
        this.map.locatedTo(item.lngLat);
        this.map.setZoom(18)
      }else{
        console.log("没有定位");
      }

    },
    changeNotice(){
      let allButton = this.buttonCount;
      let str = "";
      for(let m in allButton){
        str += this.statusLabel(m) + ":" + allButton[m] + "家,";
      }
      this.noticeStr = str.substr(0,str.length-1);
    },
    //切换单位关停
    changeDeviceStatus(status){
      console.log("device",status)
      this.dialogShow = true;
    },
    cancleDialog(){
      this.dialogShow = false;
      this.clickDevice.closed = !this.clickDevice.closed;
    },
    confirm(){
      this.clickDevice.remark = this.dialogRemark;
      this.confirmLoading = true
      this.$http({
        url: '/zuul/cultural/',
        method: 'post',
        data: this.clickDevice
      }).then(result => {
        if (result.data.success) {
          Notify({ type: 'success', message: '设置成功' });
          // this.closeReport();
        } else {
          Notify({ type: 'warning', message: '网络问题，请稍后再试' });
        }
      }).finally(f=>{
        this.confirmLoading = false;
        this.dialogShow = false;
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试'+error });
      })


    },
    // 打开检查详情
    openCheck(checkType){
      console.log(checkType,this.clickDevice,"clickDevice")
      this.$http({
        url: "/zuul/cultural/check/table/raw",
        method: "get",
        params:{
          infoType:this.clickDevice.type?this.clickDevice.type:"all",
          type:checkType
        }
      }).then(res=>{
        if(res.data.success && res.data.data){
          let table = {};
          if(!this.clickDevice.type){
            table = res.data.data.length>0?res.data.data[0]:{};
          }else{
           /* res.data.data.map(item=>{
              // console.log(item,item.infoType)
              if(("," + this.clickDevice.type + ",")==item.infoType){
                table = item;
              }
            })*/
            if(res.data.data.length>1){
              res.data.data.map(item=>{
                console.log(item,item.infoType)
                if(("," + this.clickDevice.type + ",")==item.infoType){
                  table = item;
                }
              })
            }else{
              table = res.data.data[0]
            }
          }
          this.recordList = {
            dict:table,
            ...this.makerDetail
          }
        }
      }).finally(f=>{
        this.reportListPopup = true;
      })
    },
    // 进入检查
    checkIn(checkType){
      let _this = this;
      this.$http({
        url: "/zuul/cultural/check/table/raw",
        method: "get",
        params:{
          infoType:this.clickDevice.type?this.clickDevice.type:"all",
          type:checkType
        }
      }).then(res=>{
        if(res.data.success && res.data.data){
          let table = {};
          if(!this.clickDevice.type){
            table = res.data.data.length>0?res.data.data[0]:{};
          }else{
            if(res.data.data.length>1){
              res.data.data.map(item=>{
                console.log(item,item.infoType)
                if(("," + this.clickDevice.type + ",")==item.infoType){
                  table = item;
                }
              })
            }else{
              table = res.data.data[0]
            }


          }
          console.log(table,"table")
          this.hasTemp(table);
        }
      }).finally(f=>{
         this.checkPopup = true;
        /*_this.$nextTick(()=>{
          _this.$refs.check.init();
        })*/
      })
    },
    // 查看是否有temp
    hasTemp(table){
      this.$http({
        url: "/zuul/cultural/check/hasTemp/" + this.clickDevice.id,
        method: "get",
        params:{
          type:table.id,
          person: localStorage.getItem('wljptuser')
        }
      }).then(res=>{
        console.log("checkTemp",table)
        let checkTable = {
          table: table,
          tempData:{}
        }
        if(res.data.success && res.data.data && res.data.data.content){
          checkTable.tempData = res.data.data;
        }
        this.checkTable = checkTable;
      }).catch(error=>{
        console.log("获取暂存记录错误：" + error)
      })
    }
  },
  mounted(){
    // console.log(localStorage.getItem("wljptuser"),"map page")
  },
  watch:{
    keyWords:{
      handler(n, o) {
        this.listResolve();
      },
      deep: true
    },
    buttonCount:{
      handler(n, o) {
        this.changeNotice();
      },
      deep: true
    },
    layerStyle:{
      handler(n, o) {
        this.changeButtonCount(n);
      },
      deep: true
    }
  },
  beforeDestroy() {
    if(this.map){
      this.map.destroy();
    }
  },
}
</script>

<style scoped lang="less">

/deep/ .van-badge--fixed{
  top: 9px;
  right: 8px;
}
/deep/ .button-icon .van-badge--fixed{
  top: 0px;
  right: 15px;
}
.col-left{
  text-align: left;
  line-height: 40px;
}
.col-right{
  padding: 4px 0px;
  text-align: right;
  .btn{
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    background: red;
    color: white;
    text-align: center;
  }
  .btn-left{
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    background: #2cc0f5;
  }
  .btn-right{
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    background: #0396FF;
  }
}
.model-title{
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  line-height: 50px;
  position: relative;
  .van-icon{
    position: absolute;
    left: 5px;
    top: 10px;
    font-weight: normal;
    color: #969799;
  }
}
.map{
  height: calc(100vh);
  position: relative;
  z-index: 1001;
  .layer-show{
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 1002;
    width: 40px;
    padding-top: 4px;
    height: 36px;
    background: white;
    text-align: center;
    border-radius: 8px;
    box-shadow: -2px -2px 2px #ccc;
  }

  .maker-detail{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1002;
    width: calc(100vw - 32px);
    min-height: 100px;
    background: white;
    padding: 16px;
    padding-bottom: 24px;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    box-shadow: -1px -1px 1px #eee;

    .maker-img{
      text-align: center;
    }
    .maker-name{
      font-weight: 800;
      font-size: 1rem;
      padding-bottom: 5px;
      width: 95%;
    }
    .maker-address{
      font-size: 0.8rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .close-icon{
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      right: 10px;
      top: -5px;
      font-size: 1.5rem;
      color: #1989fa;
    }

  }

  .layer-title{
    //font-size:0.6rem;
    font-weight: 900;
    padding: 5px 12px;
    background: #F5F6FA;
    letter-spacing: 0.5px;
  }
  .layer-icon{
    padding-top: 10px;
  }
  .layer-name,.layer-icon{
    //font-size: 0.6rem;
    text-align: center;
  }


  //搜索框样式
  .search-content{
    position: absolute;
    bottom: 0px;
    left:2%;
    z-index:1001;

    .district-content{
      text-align: center;
      padding: 10px;
      min-height: 100px;
    }

    .search-list{
      height: calc(100% - 60px);
      position: absolute;
      top: 37px;
      left: 0px;
      padding-bottom: 20px;
      height: 100px;
      overflow-y: scroll;
      font-size: 0.8rem;;

      .list-item{
        border-bottom: 1px solid #ebedf0;
        background: white;
        width: calc(100vw - 35px);
        padding: 2px 0px;
      }
      .list-img{
        display: inline-block;
        width: 50px;
        text-align: center;
      }
      .list-name{
        display: inline-block;
        width: calc(100% - 60px);

        .list-address{
          color: #aaa;
        }
      }
    }

  }
  .whole-content{
    background: white;
    padding: 10px 10px 0px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: calc(96% - 20px);
  }
  /deep/ .van-search{
      background: transparent;
      padding: 0px;
      box-shadow: -1px -1px 2px rgba(0,0,0,.05);

    .van-cell{
      border-radius: 8px;
      padding:0px;
    }
    .van-search__content{
      box-shadow: 2px 2px 2px rgba(0,0,0,.05);
      border-radius: 8px;
      padding: 5px 8px;
      //border-bottom-right-radius: 8px;
    }

  }
  /deep/ .maker-detail{
    .van-cell{
      padding: 0;
    }
  }

  .notice{
    position: absolute;
    left:0px;
    top: 0px;
    z-index: 3;
    background: white;
    width: calc(100vw);
  }
}
.dialog-title{
  padding-top: 10px;
  font-weight: 500;
  padding-bottom: 16px;
  line-height: 24px;
  text-align: left;
}
.dialog-content{
  padding: 16px;
}
</style>
<!--绘制工具样式-->
<style scoped>
#toolControl {
  position: absolute;
  top: 2px;
  right: 15px;
  /*width: 168px;*/
  z-index: 1001;
  text-align: right;
}
.toolItem {
  width: 30px;
  height: 30px;
  float: left;
  margin: 1px;
  padding: 4px;
  border-radius: 3px;
  background-size: 30px 30px;
  background-position: 4px 4px;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #E4E7EF;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.toolItem:hover {
  border-color: #789CFF;
}
.active {
  border-color: #D5DFF2;
  background-color: #D5DFF2;
}

#marker {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker_editor.png');
}
#polyline {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polyline.png');
}
#polygon {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/polygon.png');
}
#circle {
  background-image: url('https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/circle.png');
}
</style>
