<template>
  <div class="list">
    <van-tabbar v-model="tabValue" @change="timeChange">
      <van-tabbar-item name=0 >当日</van-tabbar-item>
      <van-tabbar-item name=1>本周</van-tabbar-item>
      <van-tabbar-item name=2 >本月</van-tabbar-item>
      <van-tabbar-item name=3  @click="openCandar">自定义</van-tabbar-item>
    </van-tabbar>
    <div class="des">{{venue.name}}共检查{{total}}次</div>
<!--    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
    <van-list
        :immediate-check="false"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad1"
    >

      <div class="check-list" v-for="(item,index) in list" :key="index" >
<!--                  <div class="item-choose">
                    <van-checkbox v-model="checked[item.id]"></van-checkbox>
                  </div>-->
        <div class="item-index">{{index+1}}</div>
        <div class="item-check">
          <div class="item-user"><van-icon name="user-o" size="14" />{{item.remark?item.remark:userName}}</div>
          <div>{{item.createTime?item.createTime.substr(0,16):"--"}}</div>
        </div>
        <div class="item-check-res" @click="viewDetail(item)">
          <span class="item-click"><van-icon name="arrow" /></span>
          {{parseRectify(item.rectify).split("\n")[0]}}
          <br>
          {{parseRectify(item.rectify).split("\n").length>1?parseRectify(item.rectify).split("\n")[1]:""}}
        </div>
        <div class="item-rectify">{{parseResult(item)}}</div>
      </div>
      <!--      <van-cell v-for="item in list" :key="item" :title="item" />v-for="item in list" :key="item" :title="item"-->
    </van-list>

    <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" :min-date="minDate" :max-range="365" :style="{ height: '500px' }"  />

  </div>
</template>

<script>
import {getStartEndByMonth,getWeekEndDate,getRecentMonth,formatDate} from "@/api/time"
import {
  PullRefresh,List,Cell,Col, Row,Icon,Checkbox,Notify,Button,Calendar,Tabbar,TabbarItem
} from 'vant';
export default {
  components:{
    [PullRefresh.name]: PullRefresh,
    [Calendar.name]: Calendar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [List.name]: List,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Icon .name]: Icon
  },
  props:{
    venue:{
      type:Object,
      default: ()=>{
        return {
          name:"场馆1",
          id:"1",

        }
      }
    },
  },
  watch: {
    /*监听场馆变化*/
    venue: {
      handler(n, o) {
        console.log(n.dict,n,"venue chang")
        this.params.page = 0;
        this.params.type = n.dict?n.dict.id:"";
        let group = {};
        if(n.dict && n.dict.typeList){
          n.dict.typeList.map(item=>{
            this.dict[item.id] = {
              name:item.name,
              id:item.id,
              type:item.groupName
            }

            if(!item.groupName){
              item.groupName = "其他";
            }
            if(!group[item.groupName]){
              group[item.groupName] = [];
            }
            group[item.groupName].push(item);
          })
        }

        this.group = group;
        this.timeChange(0)
        // this.getList();
      },
      deep: true,
      immediate:true
    }
  },
  data(){
    return {
      tabValue:0,
      calendarShow:false,
      minDate:new Date(2021,9,1),
      userName:"",
      error:false,
      loading:false,
      list:[],
      total:0,
      finished:false,
      refreshing:false,
      dict:{},
      checked:{

      },
      params:{
        startTime:"",
        endTime:"",
        size: 8,
        page: 0,
        type:"",
        checkType:0
      },
      group:{}
    }
  },
  mounted(){
    this.params.page = 0;
    this.userName= localStorage.getItem("wljptuserName");
   /* let promise = new Promise(resolve => {
      this.getDictList()
      resolve()
    })
    promise.then(res =>{
      this.timeChange(0)
    })*/
  },
  methods:{
    openCandar(){
      if(this.tabValue==3){
        this.calendarShow = true;
      }
    },
    timeChange(name){
      this.params.page = 0;
      if(name==1){
        this.params.startTime = getWeekEndDate()[0];
        this.params.endTime = getWeekEndDate()[1];
        this.getList();
      }else if(name==2){
        this.params.startTime = getStartEndByMonth()[0];
        this.params.endTime = getStartEndByMonth()[1];
        this.getList();
      }else if(name==0){
        this.params.startTime = formatDate(new Date()) + " 00:00:00";
        this.params.endTime = formatDate(new Date()) + " 23:59:59";
        this.getList();
      }else if(name==3){
        this.calendarShow = true;
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.params.page = 0;
      let [start, end] = date;
      this.calendarShow = false;
      this.params.startTime =this.formatDate(start);
      this.params.endTime =this.formatDate(end);
      this.getList();
    },
    // 获取检查记录
    getList(){
      this.finished = false;
      this.loading = true;
      if(Object.keys(this.venue).length>1){
        if(localStorage.getItem("wljptofficeIds") == '32010500'){
          this.params.person="";
        }
        /*else{
          this.params.person = localStorage.getItem("wljptuser");
        }*/
      }else{
        this.params.person="";
      }
      let _this = this;
      this.checked = {};
      this.$http({
        url: "/zuul/cultural/check?infoId=" + this.venue.id,
        // url: "/zuul/cultural/check",
        method: "get",
        params:this.params
      }).then(res=>{
        _this.list = [];
        if(res.data.success && res.data.data){
          _this.list = res.data.data.results;
          _this.total = res.data.data.total;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response=>{
        this.loading = false;
      })
    },
    // 获取检查项
    getDictList(){
      /*this.$http({
        url: "/zuul/cultural/check/table/" + GlobalConfig.checkTableType,
        method: "get"
      }).then(res=>{
        if(res.data.success){
          res.data.data.typeList.map((item,index)=>{
            if(item){
              if(index<8){
                this.dict[item.id] = {
                  name:item.name,
                  id:item.id,
                  type:"场所防疫"
                }
              }else if(index<12){
                this.dict[item.id] = {
                  name:item.name,
                  id:item.id,
                  type:"员工防护"
                }
              }else{
                this.dict[item.id] = {
                  name:item.name,
                  id:item.id,
                  type:"消费者防护"
                }
              }
            }

          })
        }
      })*/
    },
    // 解析检查结果
    parseJson(jsonstr,value){
      let str = "";
      if(jsonstr){
        let content= JSON.parse(jsonstr);
        str = content[value]
      }
      return str;
      // return value
    },
    // 解析整改项
    parseRectify(str){
      let res = "无整改项";
      if(str){
        let typeJson = {};
        let arr =str.split(",");
        arr.map(item=>{
          if(this.dict[item]){
            if(!typeJson[this.dict[item].type]){
              typeJson[this.dict[item].type] = {};
            }
            typeJson[this.dict[item].type][item] = this.dict[item].name;
          }
        })
        res = "共" + arr.length + "项需整改 \n";
        for(let k in typeJson){
          res += k + ":";
          let n = 0;
          for(let j in typeJson[k]){

            if(n==0){
              res += typeJson[k][j]
            }else{
              res +="," + typeJson[k][j]
            }
            n++;
          }
          res += n + "项需整改。";
        }
      }
      return res;
    },
    parseResult(item){
      //-1暂存，0通过，1需要整改，2整改完成
      let str = "检查通过";
      if(item.status==0){
        str = "检查通过";
      }else if(item.status==1){
        str = "需要整改";
      }else if(item.status==2){
        str = "整改完成";
      }
      return str;
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
      }
    },
    onRefresh(){
      if(this.list.length<this.total){
        this.loading = true;
        this.params.page++;
        this.onLoad();
      }else{
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
      }

    },
    getOnLoadList(){
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check?infoId=" + this.venue.id,
          method: "get",
          params:this.params
        }).then(res=>{
          resolve(res);

        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    onLoad1(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{
        if(res[0].data.success && res[0].data.data.results){
          if(_this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total ){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
      })
    },
    viewDetail(item){
      console.log(item,"cultural")
      this.$http({
        url: "/zuul/cultural/check/" + item.id,
        method: "get"
      }).then(res=>{
        if(res.data.success){
          console.log(res.data.data,"list")
          this.$emit("viewDetail1",{
            venue:this.venue,
            check:res.data.data,
            dict:this.group
          })
        }

      })

    },
    downLoad(){
      let ids = []
      for(let k in this.checked){
        if(this.checked[k]){
          ids.push(k)
        }
      }
      if(ids.length==0){
        Notify({ type: 'warning', message: '至少选择一项！' });
        return;
      }
      let url = '/zuul/cultural/check/export?ids=' + ids.join(",");
      window.open(url,"blank")

    }

  }
}
</script>

<style scoped lang="less">
    .list{
      color: #323233;
      height: calc(100vh - 80px);
      overflow: scroll;
    }
    .des{
      padding: 10px 20px;
      color: rgba(69, 90, 100, 0.6);
      line-height: 40px;
      background: #f7f8fa;
    }
    .check-list{
      padding: 10px 20px 2px;
      font-size: 0.8rem;
      border-bottom: 1px solid #ebedf0;;
      >div{
        display: inline-block;
        vertical-align: middle;
      }
      .item-choose{
        position: relative;
        width: 30px;

      }
      .item-index{
        width: 30px;
        //position: relative;
        //top: -25px;
      }
      .item-check{
        width: 80px;
        line-height: 1.2rem;
        .item-user{
          padding: 5px 0px 2px 0px;
          .van-icon{
            position: relative;
            top: 2px;
            margin-right: 2px;
          }
        }
      }
      .item-check-res,.item-rectify{
        padding: 0 2px;
        word-break: break-word;
        width: calc(100vw - 250px);
      }
      .item-check-res{
        position: relative;
        padding-left: 10px;;
        .item-click{
          position: absolute;
          top: 45%;
          height: 100%;
          right: -35px;
          width: 30px;
        }
        //color: green;
        //cursor: pointer;
      }
      .item-rectify{
        text-align: right;
        width: 80px;
      }
    }
    .list-btn{
      position: fixed;
      bottom: 0px;
      left: 0px;
      width: calc(100vw);
    }
</style>
