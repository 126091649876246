const DEFAULT_CENTER = new TMap.LatLng(31.993362852851497,118.81556749353571);
class VueTmap{
  constructor(options,target){
    if(!target){
      console.log("error:地图容器为空");
    }
    this._params = Object.assign({
      target:target,//地图装载容器
      rotation: 20,//设置地图旋转角度
      pitch:30, //设置俯仰角度（0~45）
      zoom:12,//设置地图缩放级别
      center: [119.92554, 32.45546],//设置地图中心点坐标
      showControl:false//是否显示地图上的控件
    },options)
    this._layer = {}
    this._editor = {}
    if (!this._params.async) {
      this.init();
    }
  }

  init(){
    let p = this._params;
    p.center = new TMap.LatLng(p.center[1],p.center[0]);
    this.map = new TMap.Map(p.target, p);
  }
  addLabel(id,option){
    let styles = {};
    styles[option.style.id] = new TMap.LabelStyle({
      "color": (option.style&&option.style.color)?option.style.color : '#3777FF',  // 点标记样式宽度（像素）
      "size": (option.style&&option.style.size)?option.style.size : 18,  // 点标记样式宽度（像素）
      "offset": (option.style&&option.style.offset)?option.style.offset : { x: 0, y: 0 }, // 点标记样式高度（像素）
      "angle": (option.style&&option.style.angle)?option.style.angle : 0, // 点标记样式高度（像素）
      "alignment": (option.style&&option.style.alignment)?option.style.alignment :"center", // 点标记样式高度（像素）
      "verticalAlignment": (option.style&&option.style.verticalAlignment)?option.style.verticalAlignment : "middle" // 点标记样式高度（像素）
    })
    option.data.map(item=>{
      item.position = new TMap.LatLng(item.lngLat[1],item.lngLat[0])
    })
    this._layer["label" + id]  = new TMap.MultiLabel({
      map: this.map,  //指定地图容器
      //样式定义
      styles: styles,
      //点标记数据数组
      geometries:option.data
    });
  }

  // 判断图层是否显示
  isLayerShow(type,id){
    if(this._layer[type + "" + id] && this._layer[type + "" + id]){
        return true;
    }
    return false;
  }

  hideLayer(type,id){
    if(this._layer[type + "" + id]){
      this._layer[type + "" + id].setMap(null);
    }else{
      console.log(type + "" + id + "does not exist");
    }
  }

  showLayer(type,id){
    if(this._layer[type + "" + id]){
      this._layer[type + "" + id].setMap(this.map);
    }else{
      console.log(type + "" + id + "does not exist");
    }
  }

  toggoleLayer(type,id){
    if(this._layer[type + "" + id]){
      if(this._layer[type + "" + id].map){
        this._layer[type + "" + id].setMap(null);
      }else{
        this._layer[type + "" + id].setMap(this.map);
      }
    }else{
      console.log(type + "" + id + "does not exist");
    }
  }


  addFeature(id,option){
    let styles = {};
    styles[option.style.id] = new TMap.MarkerStyle({
      "width": (option.style&&option.style.width)?option.style.width : 25,  // 点标记样式宽度（像素）
      "height": (option.style&&option.style.height)?option.style.height : 25, // 点标记样式高度（像素）
      "src": (option.style&&option.style.icon)?option.style.icon : "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",  //图片路径
      //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
      "anchor": {
        x: (option.style&&option.style.anchor)?option.style.anchor[0]:16, y: (option.style&&option.style.anchor)?option.style.anchor[1]:32 }
    })
    option.data.map(item=>{
      item.position = new TMap.LatLng(item.lngLat[1],item.lngLat[0])
    })
    this._layer["maker" + id]  = new TMap.MultiMarker({
      map: this.map,  //指定地图容器
      //样式定义
      styles: styles,
      //点标记数据数组
      geometries:option.data
    });
  }
  delFeatureById(layerId,id){
    if(this._layer["maker" + layerId]){
      this._layer["maker" + layerId].remove([id])
    }
  }
  addLayerFeature(layerId,data){
    if(this._layer["maker" + layerId]){
        let item = data.position;
        this._layer["maker" + layerId].add({
          "id": data.id,   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId":layerId,  //指定样式id
          "position": new TMap.LatLng(item[1], item[0]),  //点标记坐标位置
          "properties": {//自定义属性
            "name": data.properties.name,
            "address":data.properties.address,
            "status":data.properties.status,
            "type":data.properties.type
          }
        })
    }
  }

  // 判断marker图层是否显示
  isFeatureShow(id){
    if(this._layer["maker" + id]&&this._layer["maker" + id].map){
      return true;
    }
    return false;
  }
  toggleFeature(id){
    if(this._layer["maker" + id]){
      if(this._layer["maker" + id].map){
        this._layer["maker" + id].setMap(null);
      }else{
        this._layer["maker" + id].setMap(this.map);
      }
    }
  }

  deleteLayerData(id){
    if(this._layer["maker" + id]){
      this._layer["maker" + id].setGeometries([]);
    }
  }
  removeFeature(id){
    if(this._layer["maker" + id]){
      this._layer["maker" + id].setMap(null);
      this._layer["maker" + id] = null;
    }
  }
  isExistLayer(id){
    if(this._layer["maker" + id]){
      return true;
    }
    return false
  }
  hideFeature(id){
    if(this._layer["maker" + id]){
      this._layer["maker" + id].setMap(null);
    }
  }
  showFeature(id){
    if(this._layer["maker" + id]){
      this._layer["maker" + id].setMap(this.map);
    }
  }

  //添加多边形
  addPolygon(id,option){
    let styles = {};
    styles["default"] = new TMap.PolygonStyle({
      'color': option.style.color?option.style.color:'rgba(41,91,255,0.1)', // 面填充色
      'showBorder': option.style.showBorder?option.style.showBorder:true, // 是否显示拔起面的边线
      'borderColor': option.style.borderColor?option.style.borderColor:'rgba(255,0,0,1)', // 边线颜色
      'borderWidth': option.style.borderWidth?option.style.borderWidth: 3, // 边线宽度
      'borderDashArray': option.style.borderDashArray?option.style.borderDashArray:[5, 5] // 虚线数组
    })
    if(option.style && Array.isArray(option.style)){
      option.style.map(style=>{
        styles[style.id] = new TMap.PolygonStyle({
          'color': style.color?style.color:'rgba(41,91,255,0.1)', // 面填充色
          'showBorder': style.showBorder?style.showBorder:true, // 是否显示拔起面的边线
          'borderColor': style.borderColor?style.borderColor:'rgba(255,0,0,1)', // 边线颜色
          'borderWidth': style.borderWidth?style.borderWidth: 3, // 边线宽度
          'borderDashArray': style.borderDashArray?style.borderDashArray:[5, 5] // 虚线数组
        })
      })
    }
    option.data.map(item=>{
      item.paths = item.coordinates.map(lnglat=>{
        return new TMap.LatLng(lnglat.lat,lnglat.lng)
      })
    })
    this._layer["polygon" + id]  = new TMap.MultiPolygon({
      map: this.map,  //指定地图容器
      //面样式定义
      styles: styles,
      //面数据
      geometries:option.data
    });
  }

  //多边形事件
  polygonClickEvent(id,callback){
    this._layer["polygon" + id].on("click",callback)
  }

  //图标事件
  makerClickEvent(id,callback){
    this._layer["maker" + id].on("click",callback)
  }

  //绘制线、面
  editor(id){
    let map = this.map;
    let overlayList = [];
    let activeId = "";
    if(id && Array.isArray(id)){
      id.map((i,index)=>{
        overlayList.push({
          id: i,

          overlay: new TMap.MultiPolygon({
            map,
            styles: {
              highlight: new TMap.PolygonStyle({
                color: 'rgba(255, 255, 0, 0.6)'
              })
            },
          })
        })
        if(index==0){
          activeId = i
        }
      })

    }
    this._editor = new TMap.tools.GeometryEditor({
      map, // 编辑器绑定的地图对象
      overlayList: overlayList,
      actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, //编辑器的工作模式
      activeOverlayId:activeId,
      snappable: true // 开启邻近吸附
    });
    this._editor.on('draw_complete', (geometry) => {
      console.log(JSON.stringify(geometry),"geometry");
      // callback && callback(geometry);
    });
  }

  activeEditor(editorId){
    this._editor.setActiveOverlay(editorId);
  }

  // 移动地图中心到指定位置
  locatedTo(lnglat){
    if(lnglat && Array.isArray(lnglat)){
      this.map.setCenter(new TMap.LatLng(lnglat[1],lnglat[0]))
    }else{
      console.log("position error");
    }
  }

  // 设置地图层级
  setZoom(level){
    this.map.setZoom(level)
  }

  destroy(){
    this.map.destroy();
  }

}

window.VueTmap = VueTmap;
export default VueTmap;
