export const CurrentTime = {
    now:new Date(),
    nowDayOfWeek:new Date().getDay(),
    nowDay:new Date().getDate(),
    nowMonth:new Date().getMonth(),
    nowYear:new Date().getFullYear()
}
export const formatTime = function (date){
    date = new Date(date);
    let myyear = date.getFullYear();
    let mymonth = date.getMonth()+1;
    let myweekday = date.getDate();

    if(mymonth < 10){
        mymonth = "0" + mymonth;
    }
    if(myweekday < 10){
        myweekday = "0" + myweekday;
    }
    let myH = date.getHours();
    if(myH < 10){
        myH = "0" + myH;
    }
    let myM = date.getMinutes();
    if(myM < 10){
        myM = "0" + myM;
    }
    let myS = date.getHours();
    if(myS < 10){
        myS = "0" + myS;
    }
    return (myyear+"-"+mymonth + "-" + myweekday + " " + myH + ":" + myM + ":" + myS);
}
export const formatDate = function (date){
    date = new Date(date);
    let myyear = date.getFullYear();
    let mymonth = date.getMonth()+1;
    let myweekday = date.getDate();

    if(mymonth < 10){
        mymonth = "0" + mymonth;
    }
    if(myweekday < 10){
        myweekday = "0" + myweekday;
    }
    return (myyear+"-"+mymonth + "-" + myweekday);
}

export const getMonthDays = function (myMonth){
    let monthStartDate = new Date(CurrentTime.nowYear, myMonth, 1);
    let monthEndDate = new Date(CurrentTime.nowYear, myMonth + 1, 1);
    let days = (monthEndDate - monthStartDate)/(1000 * 60 * 60 * 24);
    return days;
}

export const getStartEndByMonth = function (){
    let start = new Date(CurrentTime.nowYear,CurrentTime.nowMonth,1);
    let end = new Date(CurrentTime.nowYear,CurrentTime.nowMonth,getMonthDays(CurrentTime.nowMonth));
    return [formatDate(start),formatDate(end)+" 23:59:59"];
}

export const getWeekEndDate = function (){
    let w = [7,1,2,3,4,5,6];
    let start = new Date(CurrentTime.nowYear,CurrentTime.nowMonth, CurrentTime.nowDay - w[CurrentTime.nowDayOfWeek]+ 1) ;
    let end = new Date(CurrentTime.nowYear,CurrentTime.nowMonth, CurrentTime.nowDay - w[CurrentTime.nowDayOfWeek] + 7 ) ;
    return [formatDate(start),formatDate(end)+" 23:59:59"];
}

export const getRecentMonth = function (){
    let end = new Date();
    let start = new Date(CurrentTime.nowYear,CurrentTime.nowMonth - 1,CurrentTime.nowDay);
    return [formatDate(start),formatDate(end)+" 23:59:59"];
}

export const getBetweenDays = function (start,end){
    let result = [];
    //使用传入参数的时间
   let startTime = new Date(start);
    let endTime = new Date(end);
    // console.log(startTime,endTime)
       while (endTime - startTime >= 0) {
          let year = startTime.getFullYear();
          let month = startTime.getMonth();
          month = month<9?'0'+(month+1):month+1;
          let day = startTime.getDate().toString().length == 1 ? "0" + startTime.getDate() : startTime.getDate();
          //加入数组
          result.push(year + "" + month + "" + day);
          //更新日期
          startTime.setDate(startTime.getDate() + 1);
      }
    return result;
}
