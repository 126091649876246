<template>
  <div>
<!--    <div class="print-img"><van-button type="default"  @click="toImg">打&nbsp;&nbsp;&nbsp;印</van-button></div>-->
    <div ref="printContent" style="background: #fff;">
      <div class="title">{{checkTitle}}检查表</div>
      <div class="content">
        <div class="item">
          <span class="col-6" style="padding: 0px;">
            <span style="display: inline-block;width: 100%;position: relative;left: 1px;border-right: 1px solid #ebedf0;">
            场所名称
              </span>
          </span>
          <span class="col-18 des">{{recordData.venue.name}}</span>
        </div>
        <div class="item">
          <span class="col-6" style="padding: 0px;">
            <span style="display: inline-block;width: 100%;position: relative;left: 1px;border-right: 1px solid #ebedf0;">
            场所地址
              </span>
          </span>
          <span class="col-18 des">{{recordData.venue.address}}</span>
        </div>
        <div class="item">
            <span class="col-17 center">检查内容</span>
            <span class="col-7 center">检查情况</span>
        </div>
        <div class="item" v-for="(item,index) in itemJson" v-bind:key="index">
         <span class="center vertical-align">
           <div style="writing-mode:tb-rl;">
              {{item.name}}
           </div>

         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item">
             <span class="des" v-for="(d,i) in item.data" v-bind:key="i + ''  + d.id">
               <span class="label">{{d.sort}}.{{d.name}}</span>
               <span class="option">
                 <van-radio-group v-model="formData[d.id]['value']" direction="horizontal" disabled>
                    <van-radio name="1" icon-size="24px">是</van-radio>
                    <van-radio name="0" icon-size="24px">否</van-radio>
                  </van-radio-group>
               </span>
             </span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
            <div style="writing-mode:tb-rl;">
            存在问题整改要求
            </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item problemContent des">
              &nbsp;存在问题:
              <div v-for="p in recordData.check.problemList" v-bind:key="p.id" style="padding-left: 10px;">
                  {{items[p.typeId]}}:{{p.checkResult}}
              </div>
               &nbsp;整改要求:
              <div v-if='formData["check"]["des"]' style="padding-left: 10px;">
               {{ formData["check"]["des"]}}
              </div>
              <div v-else style="padding-left: 10px;">
                无
              </div>
              <div style="text-align: right;">

                <img :src="recordData.check.checkerSign" height="60px" style="vertical-align: middle;">
             </div>
            <span style="position: absolute;bottom: 25px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">检查人:&nbsp;{{recordData.check.remark}}</span>
            <span style="position: absolute;bottom: 0px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">日期:&nbsp;{{recordData.check.createTime}}</span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               场所人员确认
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item rectifyContent des">
              &nbsp;确认意见及整改承诺:

            <div style="text-align: right;">
             <img :src="recordData.check.checkedSign" height="60px">
           </div>
            <span style="position: absolute;bottom: 0px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">日期:&nbsp;{{recordData.check.rectifyTime}}</span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               整改情况
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item rectifiedContent des">
              <div v-for="p in recordData.check.problemList" v-bind:key="p.id" style="padding-left: 10px;">
                  {{items[p.typeId]}}:{{p.rectifyResult?p.rectifyResult:"暂未整改"}}
              </div>
            <span style="position: absolute;bottom: 0px;right: 0px;border: none;width: auto;min-width: 180px;font-size: 14px;">日期:&nbsp;&nbsp;{{recordData.check.rectifyTime}}</span>
          </span>
        </div>
        <div class="item">
          <span class="center vertical-align">
             <div style="writing-mode:tb-rl;">
               附件
             </div>
         </span>
          <span class="col-2"></span>
          <span class="col-22 little-item picContent">
            <div v-if="(recordData.check && recordData.check.problemList && recordData.check.problemList.length>0)">
                <div v-for="p in recordData.check.problemList" v-bind:key="p.id" style="padding-left: 10px;">
                  {{items[p.typeId]}}:{{p.checkResult}}
                   <div>检查照片</div>
                  <div v-if="p.recordFileList && p.recordFileList.length>0">
                      <img  v-for="(pic,ind) in p.recordFileList" v-bind:key="ind" :src="pic" alt="" width="95%">
                 </div>
                  <div v-if="formData[p.typeId] && formData[p.typeId].files">
                       <img  v-for="(pic,ind) in formData[p.typeId].files" v-bind:key="'pro'+ind" :src="pic" alt="" width="95%">
                  </div>
                  <div v-if="p.rectifyFileList && p.rectifyFileList.length>0">
                    <div>整改照片</div>

                      <img  v-for="(pic,ind) in p.rectifyFileList" v-bind:key="ind" :src="pic" alt=""  width="95%">
                    </div>
                  </div>
            </div>
            <div v-else>
              无
            </div>
            <div style="padding-left: 10px;">
              <div>现场照片</div>
              <div v-if="pics.length>0">
                <div v-for="(pic,index) in pics" v-bind:key="'outer'+ index">
                    <img  :src="pic" alt=""  width="95%">
              </div>
              </div>
              <div v-else>
              无
            </div>

            </div>

          </span>
        </div>
      </div>
    </div>
    <div class="list-btn">
      <van-button size="large" type="primary" @click="toImg" :loading="loading">保存为图片</van-button>
    </div>
    <van-popup closeable  close-icon-position="top-left" v-model="shareShow" position="right"  :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div style="padding: 50px 16px 10px 50px;">长按图片保存</div>
      <img :src="img" alt="">
    </van-popup>
  </div>
</template>

<script>
import {RenderCheckDict} from "@/api/dict"
import html2canvas from 'html2canvas'
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';

export default {
  components:{
    [Button.name]: Button,
    [Popup.name]: Popup  ,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  name: "tableItem",
  data(){
    return {
      checkTitle:"",
      itemJson:{},
      items:{},
      formData:{},
      img:"",
      shareShow:false,
      loading:false,
      pics:[],
      dictJson:{}
    }
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
          venue:{},
          check:{}
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        if(!this.dictJson){
          this.getDict();
        }else{
          this.parseDict(n.check.type);
        }
        if(n.check.content){
          this.formData = JSON.parse(n.check.content);
        }
        for(let k in this.formData){
          if(this.formData[k].fileList){
            this.formData[k].files = this.formData[k].fileList.split(",");
          }
        }

        if(n.check.picture){
          this.pics = n.check.picture.split(",");
        }else{
          this.pics = [];
        }
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
    this.getDict();
    console.log(this.recordData)
  },
  methods:{
    // 解析不同类型的表格
    parseDict(type){
      this.itemJson = {};
      let table = this.dictJson[type];
      this.checkTitle = table.name;
      table.typeList.map((item,index)=>{
        if(item){
          if(!item.groupName){
            item.groupName = "其他";
          }
          if(!this.itemJson[item.groupName]){
            this.itemJson[item.groupName] = {
              name:item.groupName,
              data:[]
            }
          }
          item.sort = index + 1;
          this.itemJson[item.groupName].data.push(item);
          this.items[item.id] = item.name
          if(!this.formData[item.id]){
            this.$set(this.formData,item.id,null)
          }
        }
      })
      if(this.recordData.check && this.recordData.check.content){
        this.formData = JSON.parse(this.recordData.check.content);
      }
      console.log(this.itemJson,this.formData,"itemJson")
    },
    getDict(){
      this.$http({
        url: "/zuul/cultural/check/table/raw",
        method: "get"
      }).then(res=>{
        if(res.data.success){
          res.data.data.map(item=>{
            this.dictJson[item.id] = item;
          })
        }
        this.parseDict(this.recordData.check.type);

      })
     /* RenderCheckDict((data) => {
        console.log(data,"data")
        this.checkTitle = data.name;
        data.typeList.map((item,index)=>{
          if(item){
            if(!item.groupName){
              item.groupName = "其他";
            }
            if(!this.itemJson[item.groupName]){
              this.itemJson[item.groupName] = {
                name:item.groupName,
                data:[]
              }
            }
            this.itemJson[item.groupName].data.push(item);
            this.items[item.id] = item.name
            /!*this.items[item.id] = item.name;
            if(index<GlobalConfig.yqfk.type1){
              this.itemJson["type1"].data.push(item);
            }else if(index<GlobalConfig.yqfk.type1 + GlobalConfig.yqfk.type2){
              this.itemJson["type2"].data.push(item);
            }else{
              this.itemJson["type3"].data.push(item);
            }*!/
            if(!this.formData[item.id]){
              this.$set(this.formData,item.id,null)
            }
          }
        })
        if(this.recordData.check && this.recordData.check.content){
          this.formData = JSON.parse(this.recordData.check.content);
        }

        console.log(this.itemJson,"itemJson")
      })*/
    },
    parseContent(){

    },
    preview(oper)
      {
        if (oper < 10){
        let bdhtml=window.document.body.innerHTML;//获取当前页的html代码
          let sprnstr="<!--startprint"+oper+"-->";//设置打印开始区域
          let eprnstr="<!--endprint"+oper+"-->";//设置打印结束区域
          let prnhtml=bdhtml.substring(bdhtml.indexOf(sprnstr)+18); //从开始代码向后取html
        prnhtml=prnhtml.substring(0,prnhtml.indexOf(eprnstr));//从结束代码向前取html
        window.document.body.innerHTML=prnhtml;
        window.print();
        window.document.body.innerHTML=bdhtml;
      } else {
        window.print();
      }
     },
    toImg() { // 转图片打印
      this.loading = true;
      html2canvas(this.$refs["printContent"], {
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight
      }).then((canvas) => {
        // let url = canvas.toDataURL('image/jpeg', 1.0)
        const url = canvas.toDataURL();
        this.img = url;
        this.loading = false;
        this.shareShow = true;
        /*console.log(url,"url")
        var a = document.createElement('a');
        a.href = url;
        a.download = "photo";
        let e = new MouseEvent("click");
        a.dispatchEvent(e);*/
        /*
        printJS({
          printable: url,
          type: 'image',
          documentTitle: '打印图片'
        })*/
      })
    },
  }
}
</script>
<style scoped>
/deep/ .van-radio__icon{
  font-size: 16px !important;
}
/deep/ .van-button{
  height: 36px;
  border-radius: 4px;
}
/deep/ .van-radio--horizontal{
  margin-right: 4px;
}
</style>
<style scoped lang="less">
.list-btn{
  padding:20px 0px;
}
  .print-img{
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .title{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .content{
     padding:16px;

       .item{
         box-sizing: border-box;
         width: 100%;
         border: 1px solid #ebedf0;
         line-height: 30px;
         border-bottom: none;
         position: relative;
         span{
           display: inline-block;
           box-sizing: border-box;
           padding: 0px 5px;
           border-left: 1px solid #ebedf0;

           span{
             border: none;
             border-bottom: 1px solid #ebedf0;
             width: calc(100% + 1px);
             border-right:1px solid #ebedf0;
           }
           span:last-child{
             border-bottom: none;
           }
         }
         .vertical-align{
           position: absolute;
           width: 10%;
           top: 50%;
           left: -3px;
           transform: translate(0, -50%);
         }

         .little-item{
           padding: 0px;
           .label{
             width: 67.6%;
             border-bottom: none;
           }
           .option{
             width: 32.4%;
             border-right: none;
             position: relative;
             left: -1px;
             border-left: 1px solid #ebedf0;
           }
         }
         .problemContent{
           color: #666;
           min-height: 180px;
           padding-bottom: 30px;
           position: relative;
         }
         .rectifyContent{
           color: #666;
           min-height: 130px;
           padding-bottom: 30px;
           position: relative;
         }
         .rectifiedContent{
           color: #666;
           min-height: 100px;
           padding-bottom: 30px;
           position: relative;
         }
         .picContent{
           color: #666;
           min-height: 180px;
           position: relative;
           img{
             margin-right: 10px;
           }
         }
         span:first-child{
           border-left: none;
         }
       }
      .des{
        font-size: 14px;
        color: #666;
      }
      .item:last-child{
        border-bottom: 1px solid #ebedf0;
      }
      .col-2{
        width: 10%;
      }
      .col-6{
        width: 25%;
      }
      .col-18{
        width: calc(75% - 0px);
      }
      .col-17{
        width: calc(70% - 0px);
      }
      .col-7{
        width: calc(20%);
      }
      .col-22{
        width: calc(90% - 0px);
      }

      .center{
        text-align: center;
      }
  }
</style>
