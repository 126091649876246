<template>
  <!-- 手写签名组件 -->
  <div class="page sign-page">
    <div class="content">
      <div style="padding: 8px;position:relative;top: -8px;">签名区</div>
      <div class="sign-wrap" :id="'signWrap'+id">
        <canvas :id="'myCanvas'+id" width="100%" height="100%"></canvas>
      </div>
    </div>
    <div class="con-btn">
      <van-row>
        <van-col span="12"><van-button size="large"  @click="clearArea()">清除</van-button></van-col>
        <van-col span="12"><van-button size="large"  @click="saveSign()">确认</van-button></van-col>
      </van-row>
<!--      <span class="staging-btn size14" @click="clearArea()">清除签名</span>
      <span class="submit-btn size14" @click="saveSign()">确认签名</span>-->
    </div>
  </div>
</template>
<script>
import {Col, Row,Button,Notify} from 'vant';
export default {
  name: "signature",
  components:{
    [Button.name]: Button,
    [Notify.name]: Notify,
    [Row.name]: Row,
    [Col.name]: Col
  },
  props:{
    signer:{
      type:String,
      default: "checkerSign"
    },
    id:{
      type:String,
      default: "1"
    },
  },
  data() {
    return {
      image: "",
      mousePressed: false,
      c: "",
      ctx: "",
      lastX: 0,
      lastY: 0,
    };
  },
  watch: {
    /*监听主题变化*/
    signer: {
      handler(n, o) {
        console.log(n,"new signer")
        this.clearArea();
      },
      deep: true
    }
  },
  mounted() {
    this.image = "";
    this.mousePressed = false;
    let lastX, lastY;
    this.ctx = document.getElementById("myCanvas"+this.id).getContext("2d");
    this.c = document.getElementById("myCanvas"+this.id);
    let signWrap = document.getElementById("signWrap"+this.id);
    this.c.width = signWrap.clientWidth; // 设置宽度
    this.c.height = signWrap.clientHeight; // 设置高度
    // 监听touchstart事件，touchmove事件，touchend事件等事件
    this.InitThis();
  },
  methods: {
    InitThis() {
      // 触摸屏
      var that = this;
      this.c.addEventListener(
          "touchstart",
          function (event) {
            if (event.targetTouches.length == 1) {
              event.preventDefault(); // 阻止浏览器默认事件，重要
              var touch = event.targetTouches[0];
              this.mousePressed = true;
              that.Draw(
                  touch.pageX - this.offsetLeft,
                  touch.pageY - this.offsetTop,
                  false
              );
            }
          },
          false
      );
      this.c.addEventListener(
          "touchmove",
          function (event) {
            if (event.targetTouches.length == 1) {
              event.preventDefault(); // 阻止浏览器默认事件，重要
              var touch = event.targetTouches[0];
              if (this.mousePressed) {
                that.Draw(
                    touch.pageX - this.offsetLeft,
                    touch.pageY - this.offsetTop,
                    true
                );
              }
            }
          },
          false
      );
      this.c.addEventListener(
          "touchend",
          function (event) {
            if (event.targetTouches.length == 1) {
              event.preventDefault(); // 阻止浏览器默认事件，防止手写的时候拖动屏幕，重要
              this.mousePressed = false;
            }
          },
          false
      );
      // 鼠标
      this.c.onmousedown = function (event) {
        this.mousePressed = true;
        that.Draw(
            event.pageX - this.offsetLeft,
            event.pageY - this.offsetTop,
            false
        );
      };
      this.c.onmousemove = function (event) {
        if (this.mousePressed) {
          that.Draw(
              event.pageX - this.offsetLeft,
              event.pageY - this.offsetTop,
              true
          );
        }
      };
      this.c.onmouseup = function (event) {
        this.mousePressed = false;
      };
    },
    Draw(x, y, isDown) {
      console.log(x,y,isDown)
      if (isDown) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = "#000"; // 颜色
        this.ctx.lineWidth = 4; // 线宽
        this.ctx.lineJoin = "round";
        this.ctx.lineMax = 10; // 设置画笔最大线宽
        this.ctx.lineMin = 4; // 设置画笔最小线宽
        this.ctx.linePressure =0.5; // 设置画笔笔触压力
        this.ctx.smoothness = 10; // 设置画笔笔触大小变化的平滑度
        // console.log(this.lastX,this.lastY)
        this.ctx.moveTo(this.lastX, this.lastY);
        this.ctx.lineTo(x, y);
        console.log(this.lastX,this.lastY,x,y)
        this.ctx.closePath();
        this.ctx.stroke();
      }
      this.lastX = x;
      this.lastY = y;
    },
    // 清空画板
    clearArea() {
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    },
    // 提交签名
    saveSign() {
      this.checkEmpty(); // 调用 表单非空验证
    },
    checkEmpty() {
      let c = document.getElementById("myCanvas"+ this.id); // 获取html的canvas对象，我这个id="myCanvas"
      if (this.isCanvasBlank(c)) {
        Notify({ type: 'warning', message: '请在签名区域签名后再次确认' });
        // alert("请在签名区域签名后再次确认");
        return;
      } else {
        let image = this.c.toDataURL("image/png"); // 得到生成后的签名base64位  url 地址
        this.$emit("getSign",{
          value:this.signer,
          content:image
        })
        /*this.c.toBlob((blobObj) => {
          var file = new File([blobObj], "pic.png", {
            type: blobObj.type,
            lastModified: Date.now()
          })
          console.log(file,"file")
        })*/
        console.log(image); // 打印图片base64 url
      }
    },
    // 验证canvas画布是否为空函数
    isCanvasBlank(canvas) {
      var blank = document.createElement("canvas"); // 系统获取一个空canvas对象
      blank.width = canvas.width;
      blank.height = canvas.height;
      return canvas.toDataURL() == blank.toDataURL(); // 比较值相等则为空
    }
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  .content {
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 120px);
    padding: 4px;
    background: #fff;
    .sign-wrap {
      width: calc(100% - 10px);
      height: 100%;
      border: 1px solid #ebedf0;
    }
  }
  .con-btn {
    margin-top: 50px;
    width: 100%;
  }
}

</style>

