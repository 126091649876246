<template>
  <div class="company-info">
      <div class="form-item">
        <van-field
            v-model="form.name"
            readonly
            name="userName"
            label="企业名称"
            placeholder="企业名称"
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.signName"
            name="signName"
            label="店招名称"
            placeholder="店招名称"
            readonly
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.director"
            name="director"
            label="负责人"
            placeholder="请输入负责人"
            readonly
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            clickable
            name="phone"
            label="负责人电话"
            placeholder="请输入负责人电话"
            :value="form.contact1"
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.legalPerson"
            name="legalPerson"
            label="法人代表"
            placeholder="请输入法人代表"
            readonly
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            name="phone"
            label="法人电话"
            placeholder="请输入法人电话"
            :value="form.legalPersonPhone"
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.telephone"
            name="telephone"
            label="固定电话"
            placeholder="请输入固定电话"
            readonly
        />
      </div>

      <div class="form-item">
        <van-field
            readonly
            name="type"
            :value="show.type"
            label="业务范围"
            placeholder="点击选择业务范围"
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            name="proCode"
            :value="show.street"
            label="所属街道"
            placeholder="点击选择所属街道"
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            name="street"
            :value="show.officeId"
            label="管理科室"
            placeholder="点击选择管理科室"
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            type="textarea"
            rows="2"
            autosize
            name="address"
            v-model="form.address"
            label="地址"
            placeholder="请输入地址"
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.area"
            readonly
            name="area"
            label="营业面积"
            placeholder="请输入营业面积"
            type="number"
        >
        </van-field>
      </div>
      <div class="form-item">
        <van-field
            v-model="form.fax"
            readonly
            name="fax"
            label="传真"
            placeholder="请输入传真"
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.permit"
            name="permit"
            label="营业执照号码"
            placeholder="请输入营业执照号码"
            readonly
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="form.license"
            name="license"
            label="许可/备案编号"
            placeholder="请输入许可/备案编号"
            readonly
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            name="licenseStart"
            :value="form.licenseStart"
            label="批准/登记时间"
            placeholder="点击选择批准/登记时间"
        />
      </div>
      <div class="form-item">
        <van-field
            readonly
            clickable
            name="licenseEnd"
            :value="form.licenseEnd"
            label="到期时间"
            placeholder="点击选择到期时间"
        />
      </div>
      <div class="form-item">
        <van-field name="form.picture" label="企业证照">
          <template #input>
            <van-uploader v-model="fileTest"  :max-count="fileTest.length" :deletable="false"  />
          </template>
        </van-field>
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            readonly
            rows="3"
            autosize
            name="safetyResponseInfo"
            v-model="form.safetyResponseInfo"
            label="行业检查、疫情防控责任制"
            placeholder="请输入行业检查、疫情防控责任制"
        />
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            rows="3"
            autosize
            readonly
            name="safetyManageInfo"
            v-model="form.safetyManageInfo"
            label="行业检查、疫情防控管理制度"
            placeholder="请输入行业检查、疫情防控管理制度"
        />
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            rows="3"
            autosize
            readonly
            name="safetyPerson"
            v-model="form.safetyPerson"
            label="行业检查、疫情防控管理组织机构和管理人员"
            placeholder="请输入行业检查、疫情防控管理组织机构和管理人员"
        />
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            rows="3"
            readonly
            autosize
            name="personCertificate"
            v-model="form.personCertificate"
            label="消防值班人员、救生员、教练员等持证情况"
            placeholder="请输入消防值班人员、救生员、教练员等持证情况"
        />
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            rows="3"
            autosize
            readonly
            name="personCertificate"
            v-model="form.deviceInfo"
            label="大型、特种设备管理情况"
            placeholder="请输入大型、特种设备管理情况"
        />
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            readonly
            rows="3"
            autosize
            name="safetyPlan"
            v-model="form.safetyPlan"
            label="行业检查、疫情防控、应急预案建立和演练情况"
            placeholder="请输入行业检查、疫情防控、应急预案建立和演练情况"
        />
      </div>
      <div class="form-item">
        <van-field
            type="textarea"
            rows="3"
            readonly
            autosize
            name="accidentInfo"
            v-model="form.accidentInfo"
            label="事故事件调查处理情况"
            placeholder="请输入事故事件调查处理情况"
        />
      </div>
      <van-field name="switch" label="是否关停" readonly>
        <template #input>
          <van-switch v-model="form.closed" size="20" />
        </template>
      </van-field>
  </div>
</template>

<script>
import {OfficeJson, RenderCulturalDict, StreetJson} from "@/api/dict";
import {
  Button, Col,Calendar,
  NumberKeyboard,Row,Uploader,Switch,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Picker,Search,Popup
} from 'vant';
export default {
  name: "companyInfo",
  data(){
    return {
      form:{

      },
      show:{

      },
      fileTest:[],
      cultureDist:{},
      streetJson:{},
      officeJson:{}
    }
  },
  components:{
    [Switch.name]: Switch ,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Calendar.name]: Calendar,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [NumberKeyboard.name]: NumberKeyboard,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Picker.name]: Picker,
    [Search.name]: Search,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    id:{
      type:String
    }
  },
  watch: {
    /*监听变化*/
    id: {
      handler(n, o) {
        let _this = this;
        if(Object.keys(this.cultureDist).length>0){
          this.getCompany(n);
        }else{
          setTimeout(function(){
            _this.getCompany(n);
          },100);
        }
        console.log(n,"companyId")

      },
      deep: true,
      immediate:true
    }

  },
  methods:{
    // 获取企业类型
    getCulturalDict(){
      return new Promise(resolve => {
        RenderCulturalDict((data) => {
          data.map(item=>{
            this.cultureDist[item.id] = item;
          })
          resolve(true)
        })
      })
    },
    getCompany(id){
      this.$http({
        url: "/zuul/cultural/" + id,
        method: "get"
      }).then(res=>{
        console.log(res,"company");
        if(res.data.success){
          this.form = res.data.data;
          if(this.form.street && this.streetJson[this.form.street]){
            this.show.street = this.streetJson[this.form.street].name;
          }else{
            this.show.street = this.form.street;
          }
          if(this.form.officeId && this.officeJson[this.form.officeId]){
            this.show.officeId = this.officeJson[this.form.officeId].name;
          }else{
            this.show.officeId = this.form.officeId;
          }
          if(this.form.type && this.cultureDist[this.form.type]){
            this.show.type = this.cultureDist[this.form.type].name;
          }else{
            this.show.type = this.form.type;
          }
          if(this.form.files){
            this.fileTest = this.form.files.split(",").map(f=>{
              let isImage = false;
              if(f.indexOf('png')>-1 || f.indexOf('jpg')>-1 || f.indexOf('jpeg')>-1 || f.indexOf('gif')>-1 ||f.indexOf('bmp')>-1 || f.indexOf('webp')>-1){
                isImage = true
              }
              return {
                url:f,
                isImage:isImage
              }
            })
          }else {
            this.fileTest = [];
          }


        }else{
          Notify({ type: 'warning', message: "获取用户企业失败" });
        }
      }).catch(error=>{
        Notify({ type: 'warning', message: error });
      })
    }
  },
  mounted(){
    this.streetJson = StreetJson;
    this.officeJson = OfficeJson;
    let _this = this;
    Promise.all([this.getCulturalDict()]).then(res=>{
      console.log(222)
    })
  }
}
</script>

<style scoped lang="less">
  .company-info{
    padding-bottom: 40px;
  }
</style>